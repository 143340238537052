import { Route } from 'vue-router'
import store from '@/store'

export const redirectLoginRoute = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> => {

  // If not login path, continue
  if (toRoute.path !== '/login') {
    return next()
  }

  // if the user is logged in, we don't want them to access the
   // login page, so redirect them to the dashboard
  const isLoggedIn = store.getters['auth/isLoggedIn']
  if (isLoggedIn) {
    return next({ path: '/' })
  }

  // if the user was not logged in, continue to login page
  return next()
}