import { DefineGetters } from "vuex-type-helper";
import { TenantGetters, TenantState } from "./types";

export const getters: DefineGetters<TenantGetters, TenantState> = {
  getTenantsFromStore: (state: TenantState) => state.tenants,
  getEditingTenant: (state: TenantState) => state.editing,
  loadingGet: (state: TenantState) => state.loadingGet,
  loadingCreate: (state: TenantState) => state.loadingCreate,
  loadingUpdate: (state: TenantState) => state.loadingUpdate,
  loadingDelete: (state: TenantState) => state.loadingDelete,
  lastUpdate: (state: TenantState) => state.lastUpdate,
  getTenantLinks:(state:TenantState) => state.links,
  getTenantTotalRecords:({ links, tenants }:TenantState) => links && links.totalRecords ? links.totalRecords : tenants.length,
  getTenantById: ( state:TenantState ) => ( tenantId: string ) => state.tenants.find((tenant) => tenant._id === tenantId),
};
