import { Route } from "vue-router";
import store from "@/store";

export const redirectHomeRoute = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> => {
  const hasRedirect = fromRoute.query.redirectFrom;
  if (hasRedirect && hasRedirect !== "%2F" && hasRedirect !== "/") {
    return next(hasRedirect);
  }
  const { role } = store.getters["auth/getCurrentUser"];
  if ( role === "admin" ) {
    return next("/admin/dashboard");
  } else if (role === "manager") {
    return next("/manager/dashboard");
  } else if (role === "lead") {
    return next("/settings/user")
  } else {
    return next("/dashboard");
  }
};
