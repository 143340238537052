var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.outerClass }, [
    _c("div", { class: _vm.innerClass }, [
      _vm.showAsCheckbox
        ? _c(
            "svg",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.checked,
                  expression: "checked"
                }
              ],
              staticClass: "h-7 w-7 text-white",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 20 20",
                fill: "currentColor"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  d:
                    "M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z",
                  "clip-rule": "evenodd"
                }
              })
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }