import { UserState } from './types'

export function defaultState():UserState {
    return {
        users:[],
        editing: null,
        loadingGet:false,
        loadingCreate:false,
        loadingDelete:false,
        loadingUpdate:false,
        lastUpdate:new Date(),
        links:{ },
        makingApiRequest: false
    }
}

export const state = defaultState()