var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isOperations
    ? _c("th", { class: _vm.columnClassList, attrs: { scope: "col" } }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Select")])
      ])
    : _c("th", { class: _vm.columnClassList, attrs: { scope: "col" } }, [
        _c(
          "div",
          { staticClass: "flex items-end", on: { click: _vm.doSort } },
          [
            _c("div", [_vm._v(_vm._s(_vm.columnData.label))]),
            _vm.columnData.allowSort
              ? _c(
                  "svg",
                  {
                    staticClass: "h-4 w-4 ml-2 cursor-pointer",
                    class: _vm.isSortedBy ? "" : "opacity-20",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor",
                      "stroke-width": "2"
                    }
                  },
                  [
                    _vm.sortOrder === "ascending"
                      ? _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d: "M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
                          }
                        })
                      : _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            d: "M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
                          }
                        })
                  ]
                )
              : _vm._e()
          ]
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }