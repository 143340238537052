import { app } from "./app"
import { auth } from "./auth"
import { user } from "./users"
import { activities } from "./activities"
import { search } from "./search"
import { appSettings } from "./appSettings"
import { socketIO } from "./socketIO";
import {tenants } from "./tenants"
import {messages } from "./messages"
import { communication } from "./communication"
import { documents } from "./documents";
import { integrations } from "./integrations";

export default {
  app,
  auth,
  user,
  activities,
  search,
  appSettings,
  socketIO,
  tenants,
  messages,
  communication,
  documents,
  integrations
};
