<template>
    <component 
    :is="PlaceholderComponent" 
    :imageLink="imageLink"
    :messageText="messageText"
    :description="description"
    :imageWidth="imageWidth"
    :showButton="showButton"
    :buttonText="buttonText"
    :buttonAction="buttonAction"
    />
</template>

<script>
import get from "lodash/get";

import ErrorViewPlaceholder from "./ErrorViewPlaceholder.vue";
import LoadingViewPlaceholder from "./LoadingViewPlaceholder.vue";
import NoResultsPlaceholder from "./NoResultsPlaceholder.vue";

export default {
  name: "ViewStatePlaceholder",
  components: {
      ErrorViewPlaceholder,
      LoadingViewPlaceholder,
      NoResultsPlaceholder
  },
  props: {
      type: {
        type: String,
        required: true
      },
    imageLink: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    messageText: {
      type: String,
      default: "Loading",
    },
    description: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonAltText: {
      type: String,
      default: "",
    },
    showButtonAlt: {
      type: Boolean,
      default: false,
    },
    imageWidth: {
      type: String,
      default: "150",
    },
    imageClasses: {
      type: String,
      default: "",
    }
  },
  methods: {
    buttonAction() {
      console.log("ffff 2")
      this.$emit('buttonAction')
    },
    buttonAltAction() {
      this.$emit('buttonAltAction')
    },
    PulseImage({object}){
      const animation1 = object.createAnimation({scale: { x: 1.02, y: 1.02}, duration: 80})
      const animation2 = object.createAnimation({scale: { x: 1, y: 1 }, duration: 100})
      animation1
      .play()
      .then(() => animation2.play())
    }
  },
  computed: {
      PlaceholderComponent(){
        const { type } = this;
        const components = {
            error: ErrorViewPlaceholder,
            loading: LoadingViewPlaceholder,
            noResults: NoResultsPlaceholder,
        }

        return get(components, type, 'StackLayout')
      }
  }
};
</script>
