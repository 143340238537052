var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex items-center" },
    [
      _c("p", { staticClass: "mr-8 text-sm" }, [
        _vm._v(" " + _vm._s(_vm.descriptionText) + " ")
      ]),
      _c(
        "el-button",
        {
          staticClass: "rounded-none ml-0 mr-0 text-white",
          attrs: { size: "medium" },
          on: {
            click: function($event) {
              return _vm.$emit("doSave")
            }
          }
        },
        [
          _vm.isSaving
            ? _c("font-awesome-icon", {
                staticClass: "fa-spin",
                attrs: { icon: ["fal", "spinner"] }
              })
            : _c("span", [_vm._v(_vm._s(_vm.buttonText))])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }