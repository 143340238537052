import { AppState } from "./types";
import { getAppThemeAndSettingsDefaults } from "../../../helpers/appCoreBaseSettings"

export const defaultState = (): AppState => {
    return {
    loadedInitData: false,
    ...getAppThemeAndSettingsDefaults({}),
      isDarkMode: false,
      scrollTop: 0,
      pageTitle: "",
      showSidebar: true,
      hideNavigation: false,
      screenSize: '',
      window: {
        width: 0,
        height: 0
      },
      windowVisible: true,
      push_data: null,
      universalLink: null,
      app_launched: false,
      currentScreen: "",
    }
}

export const state = defaultState()