export const claimAccount = {
    claimAccountHeader: "Welcome!",
    claimAccountMessage: (displayName: string = "") => `Hi ${displayName}`,
    claimAccountActive: "Your account is now <span class='text-green-700'>active</span>.",
    claimAccountEmailText: "Send Claim Account Email",
    claimAccountEnterPassword: "Enter your new password below",
    claimAccountTokenInvalid: `Your token is invalid. Please enter your email address below.
                              You will receive an email with a link to claim your account.`,
    claimAccountAlreadyActive: `Your account is already active. <router-link to='/login' 
                                class='text-blue-500'>Login</router-link> or <router-link to='/password-reset' 
                                class='text-blue-500'>reset your password</router-link>.`,
    claimAccountRequest: `Please enter your email address below. You will receive an
                          email with a link to claim your account.`,
    claimAccountRequestSent: `A link to claim your account has been sent to your email`,
    claimAccountRequestSentMessage: "Please follow the instructions in that link to reset your password."
};
