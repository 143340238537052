import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  DocumentsState,
  DocumentsGetters,
  DocumentsMutations,
  DocumentsActions
} from "./types";

const namespaced = true;
export const namespace = "documents";

export const {
  mapState: documentsMapState,
  mapGetters: documentsMapGetters,
  mapMutations: documentsMapMutations,
  mapActions: documentsMapActions
} = createNamespacedHelpers<
  DocumentsState,
  DocumentsGetters,
  DocumentsMutations,
  DocumentsActions
>(namespace);

export const documents: Module<DocumentsState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
