import Vue from 'vue'
import App from './App.vue'
import router from './router/web'
import store from './store'
import "@/plugins/filters"
import ElementUI from 'element-ui'
import Vuelidate from 'vuelidate'
import VueProgressBar from "vue-progressbar";
import * as strings from "@/strings"
import VueContext from 'vue-context';
import VueGtag from "vue-gtag";
import analyticsTiming from "@/plugins/analyticsTiming"
import globalVariables from "@/plugins/globalVariables"
import generalWebPlugin from "@/plugins/web/general";

//@ts-ignore
import locale from 'element-ui/lib/locale/lang/en'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import fontAwesomeIcons from "@/helpers/fontAwesomeIcons"
import PageTopSaveButton from "@/components/PageTopButtons/PageTopSaveButton.vue";
import BackToLink from "@/components/BackToLink/BackToLink.vue";
import VerifiablePhoneField from "@/components/FormFields/VerifiablePhoneField.vue";
import ResourceNotFound from "@/components/NotFound/ResourceNotFound.vue";
import VueSocketio from "vue-socket.io-extended";
import socket from "@/services/socket-io";
import vmodal from "vue-js-modal";
import ModalBase from "@/components/Dialog/ModalBase.vue";
import { globalCSSMixin } from "./mixins/globalCSS";
import { globalGeneralMixin } from "./mixins/globalGeneral";

import WebBasePageHeader from "@/views/core/WebBasePageHeader.vue";
import PageTopButtons from "@/components/PageTopButtons/PageTopButtons.vue";
import PageMessage from "@/components/PageMessage/PageMessage.vue";

import ListViewComponent from "@/components/List/ListViewComponent.vue"
Vue.component("ListViewComponent", ListViewComponent);

import CustomDropdownSelect from "./components/FormFields/CustomDropdownSelect.vue";
Vue.component("CustomDropdownSelect", CustomDropdownSelect);

import PlaceholderComponent from "@/components/Placeholder/Placeholder.vue";
Vue.component("PlaceholderComponent", PlaceholderComponent);

import RadioBlockField from "@/components/FormFields/RadioBlockField.vue"
Vue.component('RadioBlockField', RadioBlockField)

///External custom libraries and filters
import {
  INFButton as IAButton,
  INFSelectField as SelectField,
  INFTextField as TextField,
  INFContentCard as UITile,
  INFTaxIdField as TaxIDField,
  INFDatePicker as DatePicker,
  INFDateTimePicker as DateTimePicker,
  INFAuthCodeInput as AuthCodeInput,
  INFCurrencyFormatter as CurrencyFormatter,
  INFHTMLContent as HTMLContent,
  INFCurrencyInput as IACurrencyInput,
  INFGetText as GetText,
  INFListView
} from "@infonaligy/inf-vue-components";


import { INFFilters, GetText as getText } from "@infonaligy/inf-vue-components"

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
Vue.use(Toast, {
  position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

Vue.use(VueSocketio, socket, {
  store
});

import pluginNotify from './plugins/web/notification'
Vue.use(pluginNotify, {
  store: store,
  router: router
})

import pluginDialog from './plugins/pluginDialog'
Vue.use(pluginDialog, {
  store: store,
  router: router
})


Vue.mixin(globalGeneralMixin)
Vue.mixin(globalCSSMixin)


Vue.use(VueGtag, {
  enabled: process.env.NODE_ENV === "production",
  config: { id: "" }
}, router);
Vue.use(INFFilters)
Vue.use(getText, { strings })
Vue.use(INFListView, { store, name: "ListView", router, searchBarName: "SearchBar" })
Vue.use(analyticsTiming)
Vue.use(vmodal);
Vue.use(globalVariables)
Vue.use(generalWebPlugin)


Vue.component('WebBasePageHeader', WebBasePageHeader)

// STYLES INTENDED TO OVERRIDE THOSE IN inf-vue-components
import 'element-ui/lib/theme-chalk/reset.css'
import './assets/css/theme-defaults.scss';
import 'vue-context/src/sass/vue-context.scss';
import './assets/css/style.css';
import 'animate.css/animate.min.css';


//Register custom fonts from fontawesome library
library.add(...fontAwesomeIcons)


Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.component('GetText', GetText)
Vue.component('IAButton', IAButton)
Vue.component('TextField', TextField)
Vue.component('SelectField', SelectField)
Vue.component('DatePicker', DatePicker)
Vue.component('DateTimePicker', DateTimePicker)
Vue.component('UITile', UITile)
Vue.component('PageMessage', PageMessage)
Vue.component('PageTopButtons', PageTopButtons)
Vue.component('PageTopSaveButton', PageTopSaveButton)
Vue.component('TaxIDField', TaxIDField)
Vue.component('BackToLink', BackToLink)
Vue.component('HTMLContent', HTMLContent)
Vue.component('AuthCodeInput', AuthCodeInput)
Vue.component("VerifiablePhoneField", VerifiablePhoneField);
Vue.component("IACurrencyInput", IACurrencyInput)
Vue.component("ResourceNotFound", ResourceNotFound);
Vue.component("CurrencyFormatter", CurrencyFormatter);
Vue.component('VueContext', VueContext)
Vue.component("ModalBase", ModalBase);

const ProgressBarOptions = {
  color: '#3182CE',
  failedColor: '#E53E3E',
  thickness: '2px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  inverse: false
};

Vue.use(VueProgressBar, ProgressBarOptions);
Vue.use(ElementUI, { locale })
Vue.use(Vuelidate)

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
