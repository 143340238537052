import { Route } from 'vue-router'

export const routerBeforeResolve = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> => {
  try {
    // For each matched route...
    for (const route of toRoute.matched) {
      await new Promise<void>((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(toRoute, fromRoute, (...args: any) => {
            // If there was a next() called with a redirect
            if (args.length) {
              // If redirecting to the same route we're coming from...
              if (fromRoute.name === args[0].name) {
                //
              }
              // Complete the redirect.
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          // Otherwise, continue resolving the route.
          resolve()
        }
      })
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return
  }

  // If we reach this point, continue resolving the route.
  next()
}
