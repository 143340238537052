import {
    SocketIOActions,
    SocketIOState,
    SocketIOMutations,
    SocketIOGetters
  } from "./types";
  import { DefineActions } from "vuex-type-helper";
  import store from "@/store";
  
  export const actions: DefineActions<
    SocketIOActions,
    SocketIOState,
    SocketIOMutations,
    SocketIOGetters
  > = {
    async socket_ioResourceUpdate({}, payload) {
      console.log("socket_ioResourceUpdate", payload);
      switch (payload.resourceType) {
        case "settings":
          if (payload.reason === "updated") {
            store.dispatch("auth/init");
          }
          break;
      }
    }
  };
  