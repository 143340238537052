
import MenuItem from "./MenuItem.vue";
import ManagerMenuItems from "./managerMenuItems";
import AdminMenuItems from "./adminMenuItems";
import UserMenuItems from "./userMenuItems"

import Vue from "vue";
import { filterItemsByFeatureFlag } from "@/helpers/helpers";

export default Vue.extend({
  name: "manager-menu",
  props: {
    menuItemClasses: {
      type: String
    },
    outerMenuClass: {
      type: String
    },
  },
  components: {
    MenuItem
  },
  methods: {
    handleDropdownCommand(command: string): void {
      switch (command) {
        case "logout": {
          const route = "/logout"
          this.$router.replace(route)
          break;
        }
        case "settings": {
          const route = "/profile"
          this.$router.push(route)
          break;
        }
      }
    },
  },
  computed: {
    getEnvironment(): string {
      return process.env.NODE_ENV || "development";
    },
    menuItems(): any[] {
      const { $_getCurrentUserRole } = this;
      if(!$_getCurrentUserRole) return [];
      switch ($_getCurrentUserRole) {
        case "user": return filterItemsByFeatureFlag(UserMenuItems({
        getEnvironment: this.getEnvironment,
      }), [])
        case "admin": return filterItemsByFeatureFlag(AdminMenuItems(), [])
        case "manager": return filterItemsByFeatureFlag(ManagerMenuItems(), [])
      }
      return []
    }
  }
});
