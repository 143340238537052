import { IRouterConfig } from "@/types";
import { RouteConfig } from "vue-router";

const dashboard: IRouterConfig = {
  path: "/manager/dashboard",
  component: () =>
    import(/* webpackChunkName: "Dashboard" */ "@/views/manager/Dashboard.vue"),
  meta: {
    pageTitle: "Dashboard",
    requireAuth: true,
    allowedRoles: ["manager"]
  }
};

const listTenants: IRouterConfig = {
  path: "/manager/tenants",
  component: () => import("@/views/manager/tenants/ListTenants.vue"),
  meta: {
    pageTitle: "Tenants",
    requireAuth: true,
    allowedRoles: ["manager"]
  }
};

const addNewTenant: IRouterConfig = {
  path: "/manager/tenants/new",
  component: () => import("@/views/manager/tenants/AddNewTenant.vue"),
  meta: {
    pageTitle: "Add New Tenant",
    requireAuth: true,
    allowedRoles: ["manager"]
  }
};

const singleTenant: IRouterConfig = {
  path: "/manager/tenants/:tenant_id",
  component: () => import("@/views/manager/tenants/SingleTenantInfo.vue"),
  meta: {
    pageTitle: "Tenant Info",
    requireAuth: true,
    allowedRoles: ["manager"]
  }
};

const adminRoutes: RouteConfig[] = [
  dashboard,
  listTenants,
  addNewTenant,
  singleTenant
];

export default adminRoutes;
