import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  TenantState,
  TenantGetters,
  TenantMutations,
  TenantActions
} from "./types";

const namespaced = true;
export const namespace = "tenants";

export const {
  mapState: tenantsMapState,
  mapGetters: tenantsMapGetters,
  mapMutations: tenantsMapMutations,
  mapActions: tenantsMapActions
} = createNamespacedHelpers<
  TenantState,
  TenantGetters,
  TenantMutations,
  TenantActions
>(namespace);

export const tenants: Module<TenantState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
