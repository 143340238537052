import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { TenantState, TenantMutations, TenantFields } from "./types";
import { ITenant } from "../../../types/interfaces";
import { getTenantDefaults } from "@/helpers/tenants"
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState
} from "@/store/helpers";


export const mutations: DefineMutations<TenantMutations, TenantState> = {
  [types.SET_STATE]: GenericSetState<TenantState, keyof TenantState>(),
  [types.SET_TENANT]: GenericSetItem<TenantState, ITenant>(
    "tenants",
    getTenantDefaults
  ),
  [types.SET_TENANT_EDIT_FIELD]: GenericSetEditField<TenantState, TenantFields>(),
  [types.SET_EDIT_TENANT]: GenericSetEdit<
  TenantState,
    string | Partial<ITenant>
  >("tenants", getTenantDefaults),
  [types.DELETE_TENANT]: GenericDeleteItem<TenantState>("tenants", "_id"),
};
