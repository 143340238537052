var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "shadow-sm border border-gray-200 dark:border-gray-900" },
    [
      _c("table", { staticClass: "min-w-full divide-y divide-gray-300" }, [
        _c("thead", { staticClass: "dark:bg-gray-900 dark:bg-opacity-30" }, [
          _c(
            "tr",
            [
              _vm._l(_vm.getTableColumns, function(column, index) {
                return _c("ListTableTH", {
                  key: `${_vm.tableKey}ListTableTH${index}`,
                  attrs: {
                    column: _vm.tableColumnRules[column],
                    columnKey: column
                  },
                  on: { sortChange: _vm.sortChange }
                })
              }),
              _vm.hasOperationsComponent || _vm.hasRowActions
                ? _c("ListTableTH", {
                    attrs: { columnKey: "operationsColumn", isOperations: true }
                  })
                : _vm._e()
            ],
            2
          )
        ]),
        _c(
          "tbody",
          _vm._l(_vm.customCompData, function(row, rowIndex) {
            return _c(
              "tr",
              {
                key: `${_vm.tableKey}ListTableTR${rowIndex}`,
                staticClass:
                  "hover:bg-gray-100 hover:bg-opacity-10 dark:hover:bg-gray-900 dark:hover:bg-opacity-10 cursor-pointer",
                class:
                  _vm.stripe && rowIndex % 2 !== 0
                    ? "bg-gray-50 dark:bg-gray-900 dark:bg-opacity-20"
                    : "bg-white dark:bg-gray-800"
              },
              _vm._l(_vm.getTableColumns, function(columnKey, columnIndex) {
                return _c("ListTableTD", {
                  key: `${_vm.tableKey}ListTableTD${columnIndex}`,
                  attrs: {
                    column: _vm.tableColumnRules[columnKey],
                    row: row,
                    columnKey: columnKey
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.onTableRowClicked(
                        row,
                        _vm.tableColumnRules[columnKey]
                      )
                    }
                  }
                })
              }),
              1
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }