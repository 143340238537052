var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "relative",
      class: {
        [_vm.fieldGroupClass]: true
      }
    },
    [
      _c(
        "div",
        { on: { click: _vm.editDialog } },
        [
          _c("TextField", {
            ref: "field_taxId",
            attrs: {
              type: "phone",
              label: _vm.label,
              placeholder: _vm.label,
              required: _vm.required,
              disabled: _vm.disableField,
              fieldGroupClass: ""
            },
            on: { input: _vm.onFieldChange },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      ),
      !_vm.editingField
        ? _c("div", { staticClass: "flex absolute left-0" }, [
            !_vm.verified && _vm.phone && !_vm.isNew
              ? _c(
                  "div",
                  { staticClass: "ml-auto" },
                  [
                    _c("VerifyPhone", {
                      attrs: {
                        phone: _vm.phone,
                        phoneType: _vm.phoneType,
                        isSettings: _vm.isSettings
                      },
                      on: {
                        setVerified: _vm.setVerify,
                        editPhone: _vm.setEditPhone
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Editing Phone Number",
            visible: _vm.editingNumberVisible,
            width: "30%",
            "show-close": false,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.editingNumberVisible = $event
            }
          }
        },
        [
          _c("PageMessage", {
            attrs: { type: "error", message: _vm.error },
            on: {
              close: function($event) {
                _vm.error = ""
              }
            }
          }),
          _c("div", [
            _vm._v(
              " The phone number has already been verified. You would have to reverify if you edit the number. Continue? "
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "el-button--primary el-button--medium is-plain",
                  on: { click: _vm.closeDialog }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "el-button--primary el-button--medium",
                  attrs: { type: "primary" },
                  on: { click: _vm.enableField }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }