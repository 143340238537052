var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.message
    ? _c(
        "div",
        { staticClass: "z-20", attrs: { type: "flex", justify: "center" } },
        [
          _c("el-alert", {
            attrs: {
              title: _vm.message,
              type: _vm.type,
              closable: _vm.closable,
              effect: _vm.effect
            },
            on: { close: _vm.close }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }