import { DefineActions } from "vuex-type-helper";
import {
  UserActions,
  UserState,
  UserMutations,
  UserGetters,
  IUserUpdatePayload,
} from "./types";
import * as types from "@/store/mutation-types";
import { userService } from "@/services/users";
import { IUserModel } from "@/types";
import {
  GenericCreateOne,
  GenericDeleteOne,
  GenericGetMany,
  GenericMakeRequest,
  GenericUpdateOne,
} from "@/store/helpers/actions";
import { cloneDeep } from "lodash";

export const actions: DefineActions<
  UserActions,
  UserState,
  UserMutations,
  UserGetters
> = {
  createUser: GenericCreateOne<
    UserState,
    UserGetters,
    UserActions,
    UserMutations,
    IUserModel
  >(userService, types.SET_USER as any),

  getUsers: GenericGetMany<UserState, UserGetters, UserActions, UserMutations>(
    userService,
    "users",
    "SET_USER"
  ),

  // getUser: GenericGetOne<UserState, UserGetters, UserActions, UserMutations>(
  //   userService,
  //   types.SET_USER
  // ),

  deleteUser: GenericDeleteOne<
    UserState,
    UserGetters,
    UserActions,
    UserMutations
  >(userService, types.DELETE_USER),
  updateUser: GenericUpdateOne<
    UserState,
    UserGetters,
    UserActions,
    UserMutations,
    IUserUpdatePayload
  >(userService, types.SET_USER),

  makeScreenViewActivity: GenericMakeRequest<
    UserState,
    UserGetters,
    UserActions,
    UserMutations
  >(userService.makeScreenViewActivity.bind(userService)),

  async getUser({commit}, { id, update_store = true}) {
    try{
      commit(types.SET_STATE, { key: "makingApiRequest", value: true });
      const response = await userService.getOne(id);
      if(update_store){
        commit(types.SET_USER, cloneDeep(response.data));
        commit(
          types.SET_STATE as any,
          { key: "editing", value: cloneDeep(response.data) } as any
        );
        commit(
          types.SET_STATE as any,
          { key: "lastUpdate", value: new Date() } as any
        );
      }
      return response.data;
    }catch(e){
      console.log(e)
    }finally{
      commit(types.SET_STATE, { key: "makingApiRequest", value: false });
    }
  },

  async searchUsers({}, query) {
    const response = await userService.getMany(query);
    return response.data;
  },
  async initUserData({ commit }, userId) {
    const response = await userService.initUserData(userId);
    const { activities } = response.data;

    if (activities) {
      commit("activities/SET_ACTIVITIES", activities, { root: true });
    }

    commit(
      "auth/SET_STATE",
      { key: "loadedInitData", value: true },
      { root: true }
    );
  },
  async searchUsersRegex({ commit }, query): Promise<IUserModel[]> {
    try {
      commit(types.SET_STATE, { key: "makingApiRequest", value: true });
      const response = await userService.search(query);
      return response.data;
    } finally {
      commit(types.SET_STATE, { key: "makingApiRequest", value: false });
    }
  },
  async requestVerifyPhoneCode({}, phone) {
    const { data } = await userService.requestVerifyPhoneCode(phone);
    return data;
  },
  async verifyPhone({ commit }, codeData) {
    const { data } = await userService.verifyPhone(codeData);
    commit(types.SET_USER, data.data);
    return data;
  },
  async assignUserToProfile({}, payload) {
    const { data } = await userService.assignUserToProfile(payload);
    return data;
  },
  async authenticatorMfaToken({commit}) {
    try{
      commit(types.SET_STATE, { key: "makingApiRequest", value: true });
      const { data } = await userService.getAuthenticatorMfaToken();
      return data;
    }finally{
      commit(types.SET_STATE, { key: "makingApiRequest", value: false });
    }
  },
  async verifyAuthenticatorMfaToken({commit},payload) {
    try{
      commit(types.SET_STATE, { key: "makingApiRequest", value: true });
      const { data } = await userService.verifyAuthenticatorMfaToken(payload);
      commit(types.SET_USER, data)
      return data;
    }finally{
      commit(types.SET_STATE, { key: "makingApiRequest", value: false });
    }
  },
  async updateDeviceToken({commit},payload) {
    try{
      commit(types.SET_STATE, { key: "makingApiRequest", value: true });
      const { data } = await userService.updateDeviceToken(payload);
      commit(types.SET_USER, data)
      return data;
    }finally{
      commit(types.SET_STATE, { key: "makingApiRequest", value: false });
    }
  },
};
