import Vue from "vue"
export default () => [
  {
    to: "/admin/dashboard",
    text: "Dashboard",
    description: "manage users",
    icon: "users-cog",
  },
  {
    to: "/admin/users",
    text: Vue.prototype.__getText('menu', 'usersLinkText'),
    description: "manage users",
    icon: "users-cog",
  }, 
  {
    to: "/messages",
    text: 'Messages',
    description: "manage messages",
    icon: "envelope"
  }, 
]