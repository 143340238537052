import pluralize from "pluralize";

export const documentsDescriptionUser = (unreadDocumentsLength: number, totalDocumentsLength: number): string => {
  if (unreadDocumentsLength > 0) {
    return `${unreadDocumentsLength} new ${pluralize(
      "document",
      unreadDocumentsLength
    )}`;
  } else {
    return `${totalDocumentsLength} ${pluralize(
      "document",
      totalDocumentsLength
    )}`;
  }
}

export const profilesDescriptionUser = (profilesWithIssuesLength: number, activeProfilesLength: number): string => {
  if (profilesWithIssuesLength > 0) {
    return `${profilesWithIssuesLength} ${pluralize(
      "profile",
      profilesWithIssuesLength
    )} need attention`;
  } else {
    return `${activeProfilesLength} active ${pluralize(
      "profile",
      activeProfilesLength
    )}`;
  }
}

export const messagesDescriptionUser = (unreadMessagesLength: number): string => {
  return `${unreadMessagesLength} unread ${pluralize(
    "messages",
    unreadMessagesLength
  )}`;
}