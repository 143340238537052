var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "router-link",
    {
      staticClass: "absolute top-0 left-0 py-2 px-4 text-xs text-gray-500",
      attrs: { to: _vm.to }
    },
    [_vm._v(_vm._s(_vm.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }