import { RouteConfig } from 'vue-router'
import { IRouterConfig } from '@/types'

import userRoutes from './user'
import adminRoutes from './admin'
import publicRoutes from './public'
import managerRoutes from "./manager";
import messageRoutes from "./messages";
import { redirectHomeRoute } from '../middleware'
import communicationRoutes from "./communications";

const wildcardRoute: IRouterConfig = {
  path: '*',
  component: () => import('@/views/error/404.vue'),
  meta: {
    requireAuth: false
  }
}

const rootRoute: IRouterConfig = {
  path: '/',
  component: () =>
    import(/* webpackChunkName: "Dashboard" */ '@/views/user/Entry.vue'),
  meta: {
    requireAuth: true,
    allowedRoles: ['admin', 'user', 'manager'],
    beforeResolve: redirectHomeRoute
  }
}

const routers: RouteConfig[] = [
  ...publicRoutes,
  ...adminRoutes,
  ...userRoutes,
  ...managerRoutes,
  ...messageRoutes,
  ...communicationRoutes,
  wildcardRoute,
  rootRoute
]

export default routers
