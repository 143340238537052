import { RouteConfig } from "vue-router";
import * as singleUserChildren from "./children.users"


const adminRoutes: RouteConfig[] = [
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/views/admin/dashboard/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard",
      requireAuth: true,
      allowedRoles: ["admin"],
      metaTags: {
        title: "Dashboard",
      }
    }
  },
  {
    path: "/admin/users",
    name: "AdminListUsers",
    component: () => import("@/views/admin/users/ListUsers/ListUsers.vue"),
    meta: {
      pageTitle: "Users",
      requireAuth: true,
      allowedRoles: ["admin", "manager"],
      metaTags: {
        title: "Users",
      }
    }
  },
  {
    path: "/admin/users/new",
    name: "AdminUsersNew",
    component: () => import("@/views/admin/users/SingleUser/AddNewUser.vue"),
    meta: {
      pageTitle: "Add New User",
      requireAuth: true,
      allowedRoles: ["admin", "manager"],
      metaTags: {
        title: "Add New User",
      }
    }
  },
  {
    path: "/admin/users/:user_id",
    name: "AdminUserEdit",
    component: () => import("@/views/admin/users/SingleUser/SingleUser.vue"),
    props: route => ({ 
      userId: route.params.user_id
    }),
    meta: {
      pageTitle: "Edit User: {userDisplayName}",
      requireAuth: true,
      allowedRoles: ["admin", "manager"],
      metaTags: {
        title: "Edit User",
      }
    },
    children: [
      singleUserChildren.UserEdit,
      singleUserChildren.UserActivity,
    ]
  },
  {
    path: "/admin/profile",
    name: "AdminProfile",
    component: () => import("@/views/user/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      requireAuth: true,
      allowedRoles: ["agent", "admin"],
      metaTags: {
        title: "Profile",
      }
    }
  }
];

export default adminRoutes;
