import BaseCollectionService  from './base.collection.service';
import { ResponseWrapper } from "@/services/response_wrapper";

class UserService extends BaseCollectionService {
    constructor() {
        super('users')
    }
    public async initUserData(id:string): Promise<ResponseWrapper> {
      const path = `${this.url}/${id}/init`
      return await this.request({
        method: 'get',
        url: path
      }, { useToken: "access" })
    }
    public async search(query:Record<string,any>): Promise<ResponseWrapper> {
      const path = `${this.url}-search`
      return await this.request({
        method: 'get',
        url: path,
        params:query
      }, { useToken: "access" })
    }
    public async requestVerifyPhoneCode(phone:Record<string,any>): Promise<ResponseWrapper> {
      const path = `${this.url}/verify/phone/request`;
      return await this.request(
        {
          method: "post",
          url: path,
          data: phone
        },
        { useToken: "access" }
      );
    }
    public async verifyPhone(data:Record<string,any>): Promise<ResponseWrapper> {
      const path = `${this.url}/verify/phone`;
      return await this.request(
        {
          method: "post",
          url: path,
          data: data
        },
        { useToken: "access" }
      );
    }
    public async assignUserToProfile(data: Record<string, any>): Promise<ResponseWrapper> {
      const { profileId, userId, action } = data
      const path = `${this.url}/${userId}/assign`;
      return await this.request(
        {
          method: "post",
          url: path,
          data: { profileId, action }
        },
        { useToken: "access" }
      );
    }
    public async makeScreenViewActivity(
      data: Record<string, any>
    ): Promise<ResponseWrapper> {
      const path = `${this.url}/screen-view`;
      return await this.request(
        {
          method: "post",
          url: path,
          data
        },
        { useToken: "access" }
      );
    }
    public async getAuthenticatorMfaToken(): Promise<ResponseWrapper> {
      const path = `${this.url}/authenticator-token`
      return await this.request({
        method: 'get',
        url: path,
      }, { useToken: "access" })
    }
    public async verifyAuthenticatorMfaToken(data: Record<string, any>): Promise<ResponseWrapper> {
      const path = `${this.url}/authenticator-token/verify`
      return await this.request({
        method: 'post',
        url: path,
        data: data
      }, { useToken: "access" })
    }
    public async updateDeviceToken(data: Record<string, any>): Promise<ResponseWrapper> {
      const { key, userId, value } = data
      const path = `${this.url}/update/device-token/${userId}`
      return await this.request({
        method: 'put',
        url: path,
        data: {
          key,
          value
        }
      }, { useToken: "access" })
    }
}

export const userService = new UserService()