
import Vue from "vue";

import ListTableTH from "./ListTableTH.vue";
import ListTableTD from "./ListTableTD.vue";
export default Vue.extend({
  components: { ListTableTH, ListTableTD },
  props: {
    customCompData: {
      type: Array,
      default() {
        return [];
      },
    },
    stripe: {
      type: Boolean,
      default: false,
    },
    sortChange: {
      type: Function,
      default: () => {},
    },
    tableRowClicked: {
      type: Function,
      default: () => {
        console.log("??");
      },
    },
    operationsComponent: {
      type: [Function, Object],
      required: false,
    },
    tableRowActions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data(){
    return {
      tableKey: Math.random().toString(36).substring(2, 15)
    }
  },
  methods: {
    onTableRowClicked(row: any, column: any) {
      this.$store.commit("list_view_module/SET_LIST_VIEW_MODULE_STATE", {
        key: "tableRowData",
        value: row,
      });
      this.$store.commit("list_view_module/SET_LIST_VIEW_MODULE_STATE", {
        key: "tableRowDataColumn",
        value: column,
      });

      if (typeof this.tableRowClicked === "function") {
        this.tableRowClicked(row);
      }
    },
  },
  computed: {
    activeTableColumns(): any {
      const columns = this.$store.getters[
        "list_view_module/getActiveTableColumns"
      ];
      return columns;
    },
    tableColumnRules(): any {
      const rules = this.$store.state.list_view_module["tableColumnRules"];
      return rules;
    },
    getTableColumns(): any[] {
      return [...this.activeTableColumns].sort(
        (a: string, b: string) =>
          (this.tableColumnRules as any)[a].position -
          (this.tableColumnRules as any)[b].position
      );
    },
    hasOperationsComponent(): boolean {
      return this.operationsComponent ? true : false;
    },
    hasRowActions(): boolean {
      return !!(this.tableRowActions && this.tableRowActions.length > 0);
    },
  },
});
