export const fields = {
  passwordFieldLabel: "Password",
  passwordFieldValidateError: (num: number) => `Minimum ${num} characters`,
  emailFieldLabel: "Email Address",
  emailFieldValidateError: "Please enter valid email address",
  confirmPasswordFieldLabel: "Confirm Password",
  confirmPasswordFieldValidateError: `Passwords do not match`,
  showPasswordLabel: "Show Password",
  firstNameLabel: "First Name",
  lastNameLabel: "Last Name",
  displayNameLabel: "Display Name",
  primaryPhoneNumberLabel: "Primary Phone Number",
  secondaryPhoneNumberLabel: "Secondary Phone Number",
  roleLabel:"Role",
  statusLabel:"Status",
  titleLabel: "Title",
  nameFieldValidateError: "Please enter valid name",
  tenantMaxAdminLabel: "Max Admins",
  tenantMaxUserLabel: "Max Users",
  tenantStatusLabel: "Status",
};
