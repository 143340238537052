var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._l(_vm.menuItems, function(menuItem, index) {
        return _c("MenuItem", {
          key: index,
          attrs: {
            to: menuItem.to,
            text: menuItem.text,
            description: menuItem.description,
            icon: menuItem.icon,
            subLinks: menuItem.subLinks || [],
            outerMenuClass: _vm.outerMenuClass,
            menuItemClasses: _vm.menuItemClasses
          }
        })
      }),
      _c(
        "el-dropdown",
        { on: { command: _vm.handleDropdownCommand } },
        [
          _c(
            "span",
            { staticClass: "py-2 px-2", class: _vm.$_css_PrimaryTextColor },
            [
              _vm._v(" Account"),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
            ]
          ),
          _c(
            "el-dropdown-menu",
            {
              staticClass: "w-48",
              class: _vm.$_css_PrimaryBackgroundColor,
              attrs: { slot: "dropdown" },
              slot: "dropdown"
            },
            [
              _c(
                "el-dropdown-item",
                {
                  class: _vm.$_css_PrimaryTextColor,
                  attrs: { command: "settings" }
                },
                [_vm._v("Settings")]
              ),
              _c(
                "el-dropdown-item",
                {
                  class: _vm.$_css_PrimaryTextColor,
                  attrs: { command: "logout" }
                },
                [_vm._v("Logout")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }