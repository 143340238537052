var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "menu-item-wrap relative h-full flex items-center",
      class: _vm.outerMenuClass,
      on: {
        mouseenter: _vm.menuItemMouseEnter,
        mouseleave: _vm.menuItemMouseLeave
      }
    },
    [
      _c("router-link", {
        attrs: { to: _vm.to, custom: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function({ isActive, href, navigate }) {
              return [
                _c("transition", { attrs: { name: "fade" } }, [
                  _c(
                    "a",
                    {
                      class: _vm.menuItemClassName(isActive),
                      attrs: { href: href },
                      on: { click: navigate }
                    },
                    [_vm._v(_vm._s(_vm.text))]
                  )
                ])
              ]
            }
          }
        ])
      }),
      _c("transition", { attrs: { name: "fade-down" } }, [
        _vm.itemHover && _vm.subLinks && _vm.subLinks.length
          ? _c(
              "div",
              {
                staticClass:
                  "submenu-items absolute right-0 top-full text-xs w-48 bg-white text-right px-4 py-4 shadow-md"
              },
              _vm._l(_vm.subLinks, function(sublink, index) {
                return _c(
                  "router-link",
                  {
                    key: index,
                    staticClass: "block mb-1 py-3",
                    attrs: { to: sublink.to }
                  },
                  [_vm._v(_vm._s(sublink.text))]
                )
              }),
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }