import { AuthState } from "./types";

export const defaultState = (): AuthState => {
    return {
        isLoginMfa: false,
        isLoggedIn: false,
        loadedInitData: false,
        user: null
    }
}

export const state = defaultState()