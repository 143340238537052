
import Vue from "vue";
export default Vue.extend({
  props: {
    isSaving: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: "Save",
    },
    descriptionText: {
      type: String,
      default: "",
    },
  },
});
