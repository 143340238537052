import BaseCollectionService from "./base.collection.service";
import { ResponseWrapper } from "./response_wrapper";

class CommunicationService extends BaseCollectionService {
  constructor() {
    super("communications");
  }

  async expireCommunication(communicationId: string): Promise<ResponseWrapper> {
    const path = `${this.url}/${communicationId}/expire`;
    return await this.request({
      method: "put",
      url: path
    });
  }

  async getCommunicationRecipients({
    id,
    query
  }: {
    id: string;
    query: Record<string, any>;
  }): Promise<ResponseWrapper> {
    const path = `${this.url}/${id}/recipients`;
    return await this.request({
      method: "get",
      url: path,
      params: query
    });
  }

  async getCommunicationMetrics(
    communicationId: string
  ): Promise<ResponseWrapper> {
    const path = `${this.url}/${communicationId}/metrics`;
    return await this.request({
      method: "get",
      url: path
    });
  }

  async getCommunicationReaders({
    communicationId,
    query
  }: {
    communicationId: string;
    query: Record<string, any>;
  }): Promise<ResponseWrapper> {
    const path = `${this.url}/${communicationId}/readers`;
    return await this.request({
      method: "get",
      url: path,
      params: query
    });
  }
}

export default new CommunicationService();
