import  Vue  from  "vue";
import { capitalize } from "lodash"
import { format } from "date-fns";

Vue.filter("capitalize", (val: string) => capitalize(val) );
Vue.filter("formatDateTime", (val: string) => format(new Date(val), "M/d/yyyy h:mm:ss a") );
Vue.filter("formatDate", (val: string) => format(new Date(val), "M/d/yyyy") );
Vue.filter("formatTime", (val: string) => format(new Date(val), "h:mm:ss a") );


Vue.filter("formatCurrency",(num: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  }).format(num);
})

Vue.filter("formatCurrencyCompact",(num: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
    compactDisplay: "short"
  } as any).format(num);
})

Vue.filter("formatCurrencyCompactLong",(num: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    notation: "compact",
    compactDisplay: "long"
  } as any).format(num);
})