import ExternalService from "./external.service";
import { ResponseWrapper } from "./response_wrapper";

class AuthSsoService extends ExternalService {
  async checkSSO(token: string): Promise<ResponseWrapper> {
    const path = `user?token=${token}`;
    return await this.request({
      method: "get",
      url: path
    });
  }
}

export default new AuthSsoService();
