import {
  IMessages,
  MessagesActions,
  MessagesState,
  MessagesMutations,
  MessagesGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import MessagesService from "@/services/messages.service";

export const actions: DefineActions<
  MessagesActions,
  MessagesState,
  MessagesMutations,
  MessagesGetters
> = {
  createMessages: GenericCreateOne<
    MessagesState,
    MessagesGetters,
    MessagesActions,
    MessagesMutations,
    IMessages
  >(MessagesService, types.SET_MESSAGE as any),
  getMessages: GenericGetMany<
    MessagesState,
    MessagesGetters,
    MessagesActions,
    MessagesMutations
  >(MessagesService, "messages", types.SET_MESSAGE as any),
  getMessage: GenericGetOne<
    MessagesState,
    MessagesGetters,
    MessagesActions,
    MessagesMutations
  >(MessagesService, types.SET_MESSAGE as any),
  deleteMessages: GenericDeleteOne<
    MessagesState,
    MessagesGetters,
    MessagesActions,
    MessagesMutations
  >(MessagesService, types.DELETE_MESSAGE as any),
  updateMessages: GenericUpdateOne<
    MessagesState,
    MessagesGetters,
    MessagesActions,
    MessagesMutations,
    { id: string; update: Partial<IMessages> }
  >(MessagesService, types.SET_MESSAGE as any)
};
