import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  MessagesState,
  MessagesMutations,
  IMessages,
  MessageFields
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";
import { getMessageDefaults} from "@/helpers/message"

export const mutations: DefineMutations<MessagesMutations, MessagesState> = {
  [types.SET_STATE]: GenericSetState<MessagesState, keyof MessagesState>(),
  [types.SET_MESSAGE]: GenericSetItem<MessagesState, IMessages>(
    "messages",
    getMessageDefaults
  ),
  [types.SET_MESSAGE_EDIT_FIELD]: GenericSetEditField<MessagesState, MessageFields>(),
  [types.SET_EDIT_MESSAGE]: GenericSetEdit<
    MessagesState,
    string | Partial<IMessages>
  >("messages", getMessageDefaults),
  [types.DELETE_MESSAGE]: GenericDeleteItem<MessagesState>("messages", "_id"),
  [types.CLEAR_MESSAGE_STORE]: GenericClearStore<MessagesState>(defaultState())
};
