
import { DefineGetters } from 'vuex-type-helper'
import { AuthGetters, AuthState } from "./types";

export const getters: DefineGetters<AuthGetters, AuthState> = {
    isLoggedIn: (state: AuthState) => state.isLoggedIn,
    isLoginMfa: (state: AuthState) => state.isLoginMfa,
    initialDataSetup: (state: AuthState) => state.loadedInitData,
    getCurrentUser: (state: AuthState) => state.user,
    getCurrentUserRole: (state: AuthState) => state.user && state.user.role ? state.user.role : null,
    getCurrentUserId: (state: AuthState) => state.user && state.user._id ? state.user._id : '',
    isCurrentUserAdmin: (state: AuthState) => state.user && state.user.role ? state.user.role === 'admin' : false,
}