import { RouteConfig } from "vue-router";

const messageRoutes: RouteConfig[] = [
  {
    path: "/messages",
    name: "ListMessages",
    component: () => import("@/views/messages/ListMessages.vue"),
    meta: {
      pageTitle: "Messages",
      requireAuth: true,
      allowedRoles: ["admin", "manager", "user"],
      metaTags: {
        title: "Messages",
      },
    },
  },
  {
    path: "/messages/new",
    name: "NewMessage",
    component: () => import("@/views/messages/NewMessage.vue"),
    meta: {
      pageTitle: "New Message",
      requireAuth: true,
      allowedRoles: ["admin", "manager", "user"],
      metaTags: {
        title: "New Message",
      },
    },
  },
  {
    path: "/messages/new/emergency",
    name: "NewEmergencyMessage",
    component: () => import("@/views/messages/NewEmergencyMessage.vue"),
    meta: {
      pageTitle: "New Emergency Message",
      requireAuth: true,
      allowedRoles: ["admin", "manager", "user"],
      metaTags: {
        title: "New Emergency Message",
      },
    },
  },
  {
    path: "/messages/new/success",
    name: "MessageSent",
    component: () => import("@/views/messages/MessageSent.vue"),
    meta: {
      pageTitle: "Message Processing",
      requireAuth: true,
      allowedRoles: ["admin", "manager", "user"],
      metaTags: {
        title: "MessageProcessing",
      },
    },
  },
  {
    path: "/messages/:message_id",
    name: "SingleMessage",
    component: () => import("@/views/messages/SingleMessage.vue"),
    meta: {
      pageTitle: "Message",
      requireAuth: true,
      allowedRoles: ["admin", "manager", "user"]
    }
  }
];

export default messageRoutes;
