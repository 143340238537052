var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col justify-center items-center h-96" },
    [
      _vm.imageLink
        ? _c("img", {
            staticClass: "mb-5",
            class: _vm.imageClasses,
            attrs: { src: _vm.imageLink, width: _vm.imageWidth }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "pt-3 pb-1 text-center font-bold text-2xl leading-tight",
          class: _vm.$_css_PrimaryTextColor
        },
        [_vm._v(_vm._s(_vm.messageText))]
      ),
      _c(
        "div",
        {
          staticClass: "pb-5 text-center leading-tight",
          class: _vm.$_css_PrimaryTextColor
        },
        [_vm._v(_vm._s(_vm.description))]
      ),
      _vm.showButton
        ? _c("IAButton", {
            attrs: {
              type: "text",
              click: () => _vm.buttonAction(),
              title: _vm.buttonText
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }