import { transform, isEqual, isObject, cloneDeep, has, get } from "lodash";
import pMinDelay from 'p-min-delay';
import Vue from "vue";

const {
  VUE_APP_MODE,
  VUE_APP_API_HTTP_SERVER = "",
} = process.env;

export function getPathAPI(): string {
  return VUE_APP_API_HTTP_SERVER
}

export function getFilesPathAPI(): string {
  return VUE_APP_API_HTTP_SERVER
}

export function validateFields(refs: any): boolean{
  const refKeys = Object.keys(refs)
  for (let i = 0; i < refKeys.length; i++) {
    if(refKeys[i].substr(0, 6) === 'field_'){
      const element = refs[refKeys[i]];
      if(element && typeof element.$v !== 'undefined'){
        element.$v['sample_text'].$touch()
        if ( element.$v['sample_text'].$error ){
          return false;
        }
      }
    }
  }
  return true;
}

export const isPromiseOrAsync = (fn: any): boolean => {
  return (
    Object.prototype.toString.call(fn) === "[object AsyncFunction]" ||
    Object.prototype.toString.call(fn) === "[object Promise]"
  );
};

export async function functionAsPromise(
  fn: any,
  thisArg: any,
  ...args: any
): Promise<any> {
  const returnValue = isPromiseOrAsync(fn)
    ? await fn.call(thisArg, ...args)
    : await Promise.resolve(fn.call(thisArg, ...args));
  return returnValue;
}


export const loadingTimeout = (time: number): Promise<void> => {
  const timeout = time || 540
  return new Promise((resolve) => {
      setTimeout(() => {
          resolve()
      }, timeout)
  })
}

export const loadingTimeoutMinimum = async (fn: Promise<any>,  time: number = 800): Promise<void> => {
  return await pMinDelay(fn, time);
}

export function ObjectDifference(object: any, base: any) {
	function changes(object: any, base: any) {
		return transform(object, function(result: any, value, key) {
			if (!isEqual(value, base[key])) {
        if(Array.isArray(value) && Array.isArray(base[key])){
          result[key] = value
        }else{
          result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;

        }
			}
		});
	}
	return changes(object, base);
}

export function hasRequiredFields (object: any, requiredFields: string[], ): boolean {
  let hasFields = true;
  const actualFields = cloneDeep(object);
  for (const field of requiredFields) {
    if (!has(actualFields, field) || !get(actualFields, field)) {
      hasFields = false;
      break;
    }
    if (
      Array.isArray(get(actualFields, field)) &&
      get(actualFields, field).length <= 0
    ) {
      hasFields = false;
      break;
    }
  }
  return hasFields;
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function isValidEmailAddress(email: string): boolean {
  return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}

export function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '…'
}

export function generatePassword() {
  const length = Math.floor(Math.random() * (36 - 24 + 1) + 24);
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let pw = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    pw += charset.charAt(Math.floor(Math.random() * n));
  }
  return pw;
}

export function focusTextFieldByRef(this: Vue, ref: string): void {
  this.$nextTick(() => {
    const { $refs } = this;
    if(Object.keys($refs).length === 0) return;
    if(typeof $refs[ref] === 'undefined') return;
    const field = $refs[ref] as Vue;
    const inputField = field.$el.querySelector("input");
    if (inputField) {
        inputField.focus();
    }    
  })
}


export function getFileIcon(file: { ext: string }): string {
  if (!file || !file.ext) return "file"
  switch(file.ext) {
    case "pdf":
      return 'file-pdf';
    case "png":
    case "jpg":
    case "jpeg":
    case "bmp":
      return 'file-image'
    case "doc":
    case "docx":
      return 'file-word';
    case "csv":
      return 'file-excel';
    default: 
      return "file";
  }
}

export function filterItemsByFeatureFlag(menuItems: any[], appTenant: any): any[] {
  const { disabledFeatures=[] } = appTenant
  const disabledFeatureKeys = disabledFeatures.map((df: any) => df.key)
  if (appTenant && disabledFeatureKeys) {
    const filteredList = []
    for (const menuItem of menuItems) {
      if (menuItem.featureFlag) {
        if (disabledFeatureKeys.includes(menuItem.featureFlag)) {
          continue
        }
      }
      if (menuItem.subLinks) {
        menuItem.subLinks = menuItem.subLinks.filter((menuItem: any) => {
          if (menuItem.featureFlag) {
            if (disabledFeatureKeys.includes(menuItem.featureFlag)) {
              return false
            }
          }
          return true
        })
      }
      filteredList.push(menuItem)
    }
    return filteredList
  }
  return menuItems
}


export function focusFieldByRef(fieldByRef: any | undefined){
  if(!fieldByRef) return;
  if(VUE_APP_MODE === "native"){
    if(typeof fieldByRef.focus === 'function' ){
      fieldByRef.focus()
    }else if(fieldByRef.nativeView && typeof fieldByRef.nativeView.focus === 'function' ){
      fieldByRef.nativeView.focus()
    }
  }else {
    if(fieldByRef instanceof Element){
      //
      const theField = fieldByRef.querySelector("input");
      if (theField) theField.focus();
    }else{
      const { $el } = fieldByRef
      const theField = $el.querySelector("input");
      if (theField) theField.focus();
    }

  }
}