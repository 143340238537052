import { DefineGetters } from "vuex-type-helper";
import { MessagesGetters, MessagesState } from "./types";

function getMockData() {
  if(typeof sessionStorage !== "undefined"){
    const stored = sessionStorage.getItem("mock_messages")
    const existing = stored ? JSON.parse(stored) : []
    return Array.isArray(existing) ? existing.reverse() : []
  }
  return []
}

export const getters: DefineGetters<MessagesGetters, MessagesState> = {
    getMessagesFromStore: (state: MessagesState) => {
      const mock = getMockData()
      return [ ...mock, ...state.messages]
    },
    getMessagesLinks:(state:MessagesState) => state.links,
    getMessagesTotalRecords:({ links, messages }:MessagesState) => links && links.totalRecords ? links.totalRecords : messages.length,
    getMessagesById: ( state:MessagesState ) => ( messageId: string ) => {
      const mock = getMockData()
      return state.messages.concat(mock).find((message) => message._id === messageId)
    }
  };
