import { Route } from 'vue-router'
// import store from '@/store'

export const redirectMfaRoute = async function(
  this: any,
  toRoute: Route,
  fromRoute: Route,
  next: any
): Promise<void> {
  // If not login path, continue
  if (!toRoute.path.includes('/mfa')) {
    return next()
  }

  const hasToken = localStorage.getItem("auth_mfa_request");
  if(!hasToken){
    return next('/login')
  }

  next()

}