<template>
  <div class="relative h-screen flex flex-col">
    <div id="header-outer" class="bg-white dark:bg-gray-800 shadow-sm relative z-50">
      <div
        class="h-16 flex justify-between items-center w-full mx-auto max-w-7xl px-4"
      >
        <div class="">
          <router-link to="/"
            ><img
              class="mx-auto h-12 w-auto"
              src="https://www.tridelta.org/wp-content/themes/tridelta/images/logo.png"
              alt="Workflow"
          /></router-link>
        </div>
        <div class="select-none cursor-pointer">
          <div class="block sm:hidden" @click="showMenuModal = !showMenuModal">
            <svg v-if="!showMenuModal" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <div class="hidden sm:flex">
            <WebAppMenu class="flex items-center space-x-4 h-16 w-full" />
          </div>
        </div>
      </div>
    </div>

    <header v-if="currentPageTitle" class="bg-white dark:bg-gray-900  shadow-sm">
      <div class="w-full max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <h1 class="text-lg leading-6 font-semibold text-gray-900 dark:text-gray-200">{{currentPageTitle}}</h1>
      </div>
    </header>

    <div id="body-outer" class="relative-z-5">
      <div class="w-full mx-auto max-w-7xl px-4 mt-8">
        <slot name="body"></slot>
      </div>
    </div>

    <div id="footer-outer" class="bg-gray-700 dark:bg-gray-900 mt-auto">
      <div class="w-full h-12 mx-auto max-w-7xl px-4 flex justify-between items-center">
        <div class="text-white">&copy; {{new Date().getFullYear()}} Infonaligy Partners</div>
        <el-switch v-model="darkMode" />
      </div>
    </div>

    <!-- -->
    <transition name="slide-menu">
      <div
        v-if="showMenuModal"
        class="absolute top-0 left-0 w-full h-full transition-transform"
        :class="
          showMenuModal ? 'z-40 translate-y-none bg-white translate-x-6' : 'z-0 -translate-y-full'
        "
      >
        <div class="pt-20 px-3">
            <WebAppMenu class="flex flex-col items-start space-y-4 " menuItemClasses="w-full py-4" outerMenuClass="w-full" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import WebAppMenu from "@/components/menu/WebAppMenu.vue";
import { appMapMutations } from "@/store/modules/app";
import get from 'lodash/get';

export default {
    components: {
    WebAppMenu
  },
  data() {
    return {
      viewMaxWidth: "1040px",
      showMenuModal: false,
    };
  },
  watch: {
    currentRoutePath: {
      handler(){
        this.showMenuModal = false
      }
    }
  },
  methods: {
    ...appMapMutations({
      setDarkMode: 'SET_DARK_MODE'
    }),
    navItemCSSClass(item) {
      console.log(item)
      const classes = [
        "inline-flex",
        "items-center",
        "px-1",
        "pt-1",
        "text-sm",
        "font-medium",
      ];
      return classes;
    },
  },
  computed: {
    darkMode: {
      get(){
        return this.$_isDarkMode
      },
      set(){
        this.setDarkMode(!this.$_isDarkMode)
      }
    },
    currentRoutePath() {
      return this.$appCurrentRoute.path
    },
    currentPageTitle() {
      const { meta = {} } =  this.$appCurrentRoute;
      let { pageTitle = "" } = meta

      const tokenRegex = /({userDisplayName|somethingElse|someOtherKey\})/;
      if(tokenRegex.test(pageTitle)){
        const allowedTokensRegex = /(\{userDisplayName*\})|(\{somethingElse*\})|({someOtherKey})/g;
        const matchedTokens = pageTitle.match(allowedTokensRegex);
        for (const matchedToken of matchedTokens) {
          switch (matchedToken) {
            case "{userDisplayName}": {
              const editingUser = this.$store.getters['user/getEditingUser']
              if(editingUser){
                const savedUser =this.$store.getters['user/getUserById'](editingUser._id)
                const displayName = get(savedUser, 'displayName');
                if(displayName){
                  pageTitle = pageTitle.replace(new RegExp(matchedToken, "g"), displayName);
                }else{
                  pageTitle = pageTitle.replace(new RegExp(matchedToken, "g"), "");
                }
              }else{
                pageTitle = pageTitle.replace(new RegExp(matchedToken, "g"), "");
              }
              break;
            }
          }
        }
      }
 
      return pageTitle
    }
  }
};
</script>
<style>
.slide-menu-enter-active,
.slide-menu-leave-active {
  /* transition: transform 0.16s; */
  /* transform: translateX(80px); */
}
.slide-menu-enter,
.slide-menu-leave-to {
  transform: translateX(1400px);
}

</style>
