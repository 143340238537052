
import Vue from "vue";
import { userMapActions, userMapMutations } from "@/store/modules/users";
import { getMinutes } from "date-fns";
import { userMapGetters } from "@/store/modules/users";

export default Vue.extend({
  props: {
    phone: {
      type: String,
      default: ""
    },
    phoneType: {
      type: String,
      default: ""
    },
    isSettings: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      editingNumberVisible: false,
      verificationCode: "",
      error: "",
      setVerified: true,
      value: "",
      editPhone: false,
      codeSent: false,
      sendCode: false
    };
  },
  methods: {
    ...userMapActions(["requestVerifyPhoneCode", "verifyPhone", "getUser"]),
    ...userMapMutations(["SET_EDIT_USER"]),
    async sendVerificationCode(phone: string, type: string): Promise<void> {
      this.sendCode = false;
      this.codeSent = true;
      try {
        let phoneNumber = {};
        if (type === "primary") {
          phoneNumber = {
            phone: {
              primary: phone
            }
          };
        } else if (type === "secondary") {
          phoneNumber = {
            phone: {
              secondary: phone
            }
          };
        }
        this.dialogVisible = true
        await this.requestVerifyPhoneCode(phoneNumber);
        if(this.getEditingUser){
          await this.getUser({id: this.getEditingUser._id});
        }
      } catch (e) {
        this.error = e.message;
      }
    },
    async verifyCode(): Promise<void> {
      try {
        const code = {
          code: this.verificationCode,
          phoneType: this.phoneType
        };
        await this.verifyPhone(code);
        if(this.getEditingUser){
          this.SET_EDIT_USER(this.getEditingUser._id);
        }
        this.dialogVisible = false;
        this.$emit("setVerified", true);
        this.$emit("editPhone", false);
      } catch (e) {
        this.error = e.message;
      }
    },
    async enterCode(): Promise<void> {
      this.dialogVisible = true;
    },
    async openSendCode(): Promise<void> {
      this.sendCode = true;
    },
    async closeSendCode(): Promise<void> {
      this.sendCode = false;
    }
  },
  computed: {
    ...userMapGetters(["getEditingUser", "getUserById"]),
    hasCode(): any {
      let value: boolean = false;
      if(!this.getEditingUser) return false
      switch (this.phoneType) {
        case "primary":
          if (this.getEditingUser.hasPrimaryCode) {
            value = true;
          } else {
            value = false;
          }
          break;
        case "secondary":
          if (this.getEditingUser.hasSecondaryCode) {
            value = true;
          } else {
            value = false;
          }
          break;
      }
      return value;
    },
    resendCodeTime(): number | Date {
      let dt: any;
      if(!this.getEditingUser) return 0
      switch (this.phoneType) {
        case "primary":
          if (!this.getEditingUser.phone.primaryVerified.resendCodeDate)
            return 0;
          dt =
            this.getEditingUser.phone.primaryVerified.resendCodeDate instanceof
            Date
              ? this.getEditingUser.phone.primaryVerified.resendCodeDate
              : new Date(
                  this.getEditingUser.phone.primaryVerified.resendCodeDate
                );
          break;
        case "secondary":
          if (!this.getEditingUser.phone.secondaryVerified.resendCodeDate)
            return 0;
          dt =
            this.getEditingUser.phone.secondaryVerified
              .resendCodeDate instanceof Date
              ? this.getEditingUser.phone.secondaryVerified.resendCodeDate
              : new Date(
                  this.getEditingUser.phone.secondaryVerified.resendCodeDate
                );
          break;
      }

      return getMinutes(dt) - getMinutes(new Date());
    }
  }
});
