<template>
  <div
    @mouseover="isMouseOver = true"
    @mouseleave="isMouseOver = false"
    @mousedown="isMouseFocus = true"
    @mouseup="isMouseFocus = false"
    @click="handleUpdate"
    :class="outerClass"
  >
    <!-- a mask to make this test not :selectable -->
    <div class="absolute top-0 left-0 w-full h-full z-10" :class="disabled ? 'cursor-not-allowed' : 'cursor-pointer'"></div>
    <!-- end mask -->
    <div :class="innerClass">
      <div class="w-4" v-if="showRadio">
        <RadioBlockFieldInnerButton
          :hover="isMouseOver"
          :checked="active"
          :focus="isMouseFocus"
          :disabled="disabled"
          :showAsCheckbox="showAsCheckbox"
        />
      </div>
    <div>
    </div>
     <slot name="labelHasValue">
       <span :class="labelClass"
          >{{ label }}</span
        ><span :class="subLabelClass" v-if="subLabel">{{ subLabel }}</span>
     </slot>
    </div>
  </div>
</template>

<script>

import RadioBlockFieldInnerButton from "./RadioBlockFieldInnerButton";

export default {
  components: {
    RadioBlockFieldInnerButton
  },
  props: {
    label: {
      type: String,
      required: true
    },
    subLabel: {
      type: String,
      required: false
    },
    subLabelClass: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: [String, Boolean],
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    displayDirection: {
      type: String,
      default: "vertical"
    },
    showRadio: {
      type: Boolean,
      required: false,
      default: true
    },
    showAsCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    reverseInner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isMouseOver: false,
      isMouseFocus: false
    };
  },
  methods: {
    handleUpdate() {
      if(!this.disabled){
        const { value } = this.$props;

        console.log("wow", typeof value, value, this.showAsCheckbox)
        if(typeof value === 'boolean' && this.showAsCheckbox){
          console.log("yeh ", value, !value)
          this.$emit("input", !value);
          this.$emit("change", !value);
        }else{
          this.$emit("input", value);
          this.$emit("change", value);
        }

      }
    }
  },
  computed: {
    outerClass() {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        displayDirection,
      } = this;

      const baseClasses = [
        "relative",
        "border",
        "border-gray-400 dark:border-gray-900",
        "rounded-md",
        "flex",

      ];

      const hoverClass = ["border-gray-900 dark:border-gray-700"];
      const focusClass = ["border-gray-300 dark:border-black", "shadow-sm"];
      const selectedClass = [
        "bg-gray-200 dark:bg-gray-800",
        "border-gray-400 dark:border-gray-900",
        "scale-105"
      ];

      const isStyleVertical = ["py-4", "px-6"];
      const isStyleHorizontal = ["py-2", "px-4", "items-center"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i) => [i, true])),
        Object.fromEntries(hoverClass.map((i) => [i, hover])),
        Object.fromEntries(selectedClass.map((i) => [i, active])),
        Object.fromEntries(focusClass.map((i) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    },
    innerClass() {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        disabled,
        displayDirection,
        reverseInner
      } = this;

      const baseClasses = ["w-full", "flex"];

      const hoverClass = [];
      const focusClass = [];
      const selectedClass = [];

      const isStyleVertical = [`${reverseInner ? "flex-col-reverse" : "flex-col"}`, "justify-center items-center"];
      const isStyleHorizontal = [`${reverseInner ? "flex-row-reverse" : "flex-row"}`, "justify-start items-center"];
      const disabledClass = ["opacity-30", "bg-opacity-50"];

      return Object.assign(
        {},
        Object.fromEntries(disabledClass.map((i) => [i, disabled])),
        Object.fromEntries(baseClasses.map((i) => [i, true])),
        Object.fromEntries(hoverClass.map((i) => [i, hover])),
        Object.fromEntries(selectedClass.map((i) => [i, active])),
        Object.fromEntries(focusClass.map((i) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    },
    labelClass() {
      const {
        isMouseOver: hover,
        isMouseFocus: focus,
        active,
        displayDirection,
        $_css_PrimaryTextColor

      } = this;

      const baseClasses = [
        "select-none",
        "whitespace-nowrap",
        "font-medium",
        $_css_PrimaryTextColor,
      ];

      const hoverClass = [];
      const focusClass = [];
      const selectedClass = [];

      const isStyleVertical = ["mt-3"];
      const isStyleHorizontal = ["ml-3"];

      return Object.assign(
        {},
        Object.fromEntries(baseClasses.map((i) => [i, true])),
        Object.fromEntries(hoverClass.map((i) => [i, hover])),
        Object.fromEntries(selectedClass.map((i) => [i, active])),
        Object.fromEntries(focusClass.map((i) => [i, focus])),
        Object.fromEntries(
          isStyleVertical.map((i) => [
            i,
            displayDirection === "vertical"
          ])
        ),
        Object.fromEntries(
          isStyleHorizontal.map((i) => [
            i,
            displayDirection === "horizontal"
          ])
        )
      );
    }
  }
};
</script>
