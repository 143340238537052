
interface IGtagObject {
  name: string;
  value: number;
  event_category?: string;
  event_label?: string;
}
class MeasurePerformance {
  private startTime: number;
  private endTime: number;
  private name: string;
  private label: string;
  private category: string;
  private gtag: any;

  constructor(nameOfTimer: string, label: string, category: string, gtag: any) {
    this.name = nameOfTimer;
    this.category = category;
    this.label = label;
    this.startTime = performance.now();
    this.endTime = 0;
    this.gtag = gtag;
  }

  private end = (): number => (this.endTime = performance.now());
  private total = (): number => Math.round(this.endTime - this.startTime);

  public done = (silent?: boolean): void => {
    this.end();

    const gtagObject: IGtagObject = {
      'name' : this.name,
      'value' : this.total(),
    }

    if(this.category){
      gtagObject['event_category'] = this.category
    }

    if(this.label){
      gtagObject['event_label'] = this.label
    }

    this.gtag.time(gtagObject)

    if (!silent) {
      console.log(gtagObject)
    }
  };
}

export default function install(Vue: any){
  Vue.prototype.__gtagMeasureTiming = function (name: string, label: string = "", category: string = "") {
    return new MeasurePerformance(name, label, category, this.$gtag)
  }


}

