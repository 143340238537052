<template web>
<div class="flex flex-col justify-center items-center h-96">
  <img v-if="imageLink" class="mb-5" :src="imageLink" :width="imageWidth" :class="imageClasses" />
  <div class="pt-3 pb-1 text-center font-bold text-2xl leading-tight" :class="$_css_PrimaryTextColor">{{messageText}}</div>
  <div class="pb-5 text-center leading-tight" :class="$_css_PrimaryTextColor">{{description}}</div>
    <IAButton
       type="text"
       v-if="showButton"
       :click="() => buttonAction()"
       :title="buttonText"
     />
</div>
</template>


<script>
export default {
  name: "NoResultsPlaceholder",
  props: {
    imageLink: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    messageText: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonAltText: {
      type: String,
      default: "",
    },
    showButtonAlt: {
      type: Boolean,
      default: false,
    },
    imageWidth: {
      type: String,
      default: "150",
    },
    imageClasses: {
      type: String,
      default: "",
    },
    buttonAction: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    // buttonAction() {
    //   console.log("ok button action")
    //   this.$emit('buttonAction')
    // },
    buttonAltAction() {
      this.$emit('buttonAltAction')
    },
    PulseImage({object}){
      const animation1 = object.createAnimation({scale: { x: 1.02, y: 1.02}, duration: 80})
      const animation2 = object.createAnimation({scale: { x: 1, y: 1 }, duration: 100})
      animation1
      .play()
      .then(() => animation2.play())
    }
  },
};
</script>
