var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.PlaceholderComponent, {
    tag: "component",
    attrs: {
      imageLink: _vm.imageLink,
      messageText: _vm.messageText,
      description: _vm.description,
      imageWidth: _vm.imageWidth,
      showButton: _vm.showButton,
      buttonText: _vm.buttonText,
      buttonAction: _vm.buttonAction
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }