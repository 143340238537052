var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class:
        "flex flex-row z-10 justify-end  items-center w-full  h-12 bg-white py-8 px-8"
    },
    [
      _c("header", { staticClass: "bg-white shadow-sm w-full" }, [
        _c(
          "div",
          {
            staticClass:
              "max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center"
          },
          [
            _c(
              "h1",
              { staticClass: "text-lg leading-6 font-semibold text-gray-900" },
              [_vm._v("Dashboard")]
            ),
            _c("IAButton", {
              attrs: {
                title: "Create Communication",
                click: () => {},
                type: "primary"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }