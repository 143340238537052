var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.isSettings
        ? _c("div", [
            _c("span", { staticClass: "text-red-400 text-xs" }, [
              _vm._v(_vm._s(_vm.__getTextUcFirst("simple", "unverified")))
            ])
          ])
        : _c("div", [
            !_vm.hasCode && !_vm.codeSent
              ? _c(
                  "span",
                  {
                    staticClass: "cursor-pointer text-red-400 text-xs",
                    on: { click: _vm.openSendCode }
                  },
                  [_vm._v(_vm._s(_vm.__getText("global", "verifyText")))]
                )
              : _vm._e(),
            _vm.hasCode || _vm.codeSent
              ? _c(
                  "span",
                  {
                    staticClass: "cursor-pointer text-red-400 text-xs",
                    on: { click: _vm.enterCode }
                  },
                  [_vm._v(_vm._s(_vm.__getText("global", "enterCodeText")))]
                )
              : _vm._e()
          ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Send Verification Code",
            visible: _vm.sendCode,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.sendCode = $event
            }
          }
        },
        [
          _c("PageMessage", {
            attrs: { type: "error", message: _vm.error },
            on: {
              close: function($event) {
                _vm.error = ""
              }
            }
          }),
          _c("div", { staticClass: "pb-8" }, [
            _vm._v(
              " " +
                _vm._s(_vm.__getText("global", "sendverificationCodeText")) +
                " "
            ),
            _c("p", { staticClass: "font-bold" }, [_vm._v(_vm._s(_vm.phone))])
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "el-button--primary el-button--medium is-plain",
                  attrs: { type: "primary" },
                  on: { click: _vm.closeSendCode }
                },
                [_vm._v(_vm._s(_vm.__getTextUcFirst("simple", "cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "el-button--primary el-button--medium",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.sendVerificationCode(_vm.phone, _vm.phoneType)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.__getTextUcFirst("simple", "confirm")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Verify Phone Number",
            visible: _vm.dialogVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("PageMessage", {
            attrs: { type: "error", message: _vm.error },
            on: {
              close: function($event) {
                _vm.error = ""
              }
            }
          }),
          _c("div", [
            _vm.resendCodeTime > 0
              ? _c("div", [
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.__getText(
                          "global",
                          "resendCodeText",
                          _vm.resendCodeTime
                        )
                      )
                    )
                  ])
                ])
              : _vm.codeSent
              ? _c("div", [
                  _c("div", [
                    _vm._v(_vm._s(_vm.__getText("global", "resendCodeText", 5)))
                  ])
                ])
              : _c(
                  "div",
                  {
                    staticClass: "cursor-pointer text-blue-500 hover:underline"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            return _vm.sendVerificationCode(
                              _vm.phone,
                              _vm.phoneType
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.__getTextUcFirst("simple", "resendCodeLink")
                          )
                        )
                      ]
                    )
                  ]
                )
          ]),
          _c("div", { staticClass: "pb-8" }, [
            _vm._v(
              " " +
                _vm._s(_vm.__getText("global", "verificationCodeSentText")) +
                " "
            )
          ]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticClass: "w-full" },
                [
                  _c("AuthCodeInput", {
                    attrs: { loading: false },
                    on: {
                      input: function($event) {
                        _vm.verificationCode = $event
                      },
                      hasFullCode: _vm.verifyCode
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.verifyCode }
                },
                [_vm._v(_vm._s(_vm.__getTextUcFirst("simple", "verify")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }