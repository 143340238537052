
import Vue from "vue";
import { userMapActions, userMapMutations, userMapGetters } from "@/store/modules/users";
import VerifyPhone from "@/components/User/VerifyPhone.vue";
import get from "lodash/get"

export default Vue.extend({
  components: {
    VerifyPhone
  },
  props: {
    phone: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    phoneType: {
      type: String,
      default: ""
    },
    verified: {
      type: Boolean
    },
    fieldGroupClass: {
      type: String,
      default: "pb-12"
    },
    isSettings: {
      type: Boolean,
      required: true,
      default: false
    },
    isNew: {
      type: Boolean,
      required: true
    },
    required: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      editingNumberVisible: false,
      verificationCode: "",
      error: "",
      setVerified: true,
      value: "",
      editPhone: false
    };
  },
  mounted() {},
  methods: {
    ...userMapActions(["requestVerifyPhoneCode", "verifyPhone"]),
    ...userMapMutations(["SET_EDIT_USER"]),

    async enableField(): Promise<void> {
      this.setVerified = false;
      this.editingNumberVisible = false;
    },
    async editDialog(): Promise<void> {
      if (this.editPhone === false && this.verified && !this.isNew) {
        this.editingNumberVisible = true;
      }
      this.editPhone = true;
    },
    async closeDialog(): Promise<void> {
      this.editingNumberVisible = false;
      this.editPhone = false;
    },
    onFieldChange(): void {
      this.$emit("input", this.value);
    },
    setVerify(value: any) {
      this.setVerified = value;
    },
    setEditPhone(value: any) {
      this.editPhone = value;
    }
  },
  watch: {
    phone: {
      handler(v) {
        this.value = v;
      },
      immediate: true
    },
    verified: {
      handler() {},
      immediate: true
    }
  },
  computed: {
    ...userMapGetters(["getEditingUser", "getUserById"]),
    disableField(): any {
      return this.verified && this.setVerified && !this.isNew ? true : false;
    },
    saved(): any {
      if(!this.getEditingUser) return {}
      const { _id } = this.getEditingUser;
      if (_id) {
        return this.getUserById(_id);
      }
      return {};
    },
    editingField(): boolean {
        return this.getEditingUser && this.getEditingUser["phone"] && this.saved["phone"] ? 
        get(this.getEditingUser.phone, this.phoneType) !== get(this.saved.phone, this.phoneType) : false;
    }
  }
});
