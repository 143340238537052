import { IRouterConfig } from "@/types";


export const UserEdit: IRouterConfig = {
    path: "edit",
    component: () => import("@/views/admin/users/SingleUser/UserEdit.vue"),
    props: route => ({ 
        userId: route.params.user_id
    }),
    meta: {
      pageTitle: "User: {userDisplayName}",
      requireAuth: true,
      allowedRoles: ["admin", "manager"],
      metaTags: {
        title: "User Info",
      }
    }
};


export const UserActivity: IRouterConfig = {
    path: "activities",
    component: () => import("@/views/admin/users/SingleUser/Activity/Activity.vue"),
    props: route => ({ 
        userId: route.params.user_id
    }),
    meta: {
      pageTitle: "User Activities: {userDisplayName}",
      requireAuth: true,
      allowedRoles: ["admin", "manager"],
      metaTags: {
        title: "User Activities",
      }
    }
};