import { boxClasses } from "@/helpers/modals";
import Dialog from "@/components/GlobalDialog/Dialog.vue";

const pluginDialog = {
  install(Vue: any) {
    Vue.prototype.$dialog = function ({
      title = "",
      description = "",
      primaryButtonText = "Confirm",
      secondaryButtonText = "Cancel",
      icon = "",
      slotTemplate = "none",
      slotData = {},
      onConfirm = () => {},
      onCancel = () => {},
      beforeClose = () => {},
    }) {
      if(!title) return;

      this.$modal.show(
        Dialog,
        {
          icon,
          title,
          description,
          onConfirm,
          onCancel,
          slotTemplate,
          slotData,
          primaryButtonText,
          secondaryButtonText
        },
        {
          name: "DialogModal", // needs this to close
          height: "100%",
          width: "100%",
          adaptive: true,
          classes: boxClasses("not-full", "top", "", true),
          styles: {
            position: "static",
          },
        },
        {
          "before-close": beforeClose,
        }
      );
    };
  },
};

export default pluginDialog;
