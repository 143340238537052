var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide-fade" } },
    [
      _c(
        "modal",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          ref: "self",
          class: _vm.modalWrapperClass,
          attrs: {
            height: "auto",
            adaptive: true,
            name: _vm.$attrs.name,
            classes: _vm.boxClasses,
            clickToClose: _vm.clickToClose,
            showClose: _vm.showClose
          },
          on: {
            "before-open": _vm.beforeOpen,
            "before-close": _vm.beforeClose,
            opened: _vm.opened,
            closed: _vm.closed
          }
        },
        [
          _vm.showClose
            ? _c("div", { staticClass: "absolute top-3 right-3" }, [
                _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$modal.hide(_vm.$attrs.name)
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "text-gray-500 hover:text-gray-700 text-xl",
                      attrs: { icon: ["fal", "times"] }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "w-full -mt-18" }, [
            _vm.title
              ? _c("div", { staticClass: "text-xl font-medium" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              : _c(
                  "div",
                  { staticClass: "text-xl font-medium" },
                  [_vm._t("title")],
                  2
                ),
            _vm.description
              ? _c("div", [_vm._v(_vm._s(_vm.description))])
              : _vm._e(),
            _vm.error ? _c("div", [_vm._v(_vm._s(_vm.error))]) : _vm._e()
          ]),
          _c("div", { staticClass: "w-full my-4" }, [_vm._t("default")], 2),
          _c(
            "div",
            { staticClass: "w-full flex justify-end" },
            [
              _vm.bottomButtonSecondary && _vm.bottomButtonSecondary.key
                ? _c("IAButton", {
                    staticClass: "mr-2",
                    attrs: {
                      type: "text",
                      click: () => _vm.$modal.hide(_vm.$attrs.name),
                      title: "Cancel",
                      disabled: false
                    }
                  })
                : _vm._e(),
              _vm.bottomButtonPrimary && _vm.bottomButtonPrimary.key
                ? _c("IAButton", {
                    attrs: {
                      click: () => _vm.$emit("primaryButtonClick"),
                      title: _vm.bottomButtonPrimary.label,
                      disabled: _vm.bottomButtonPrimary.disabled
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }