import { IRouterConfig } from '@/types'
import { RouteConfig } from 'vue-router'
import { redirectLoginRoute, redirectMfaRoute, redirectMfaVerify } from '../../middleware'


const login: IRouterConfig = {
  path: '/login',
  name: 'Login',
  component: () => import('@/views/public/Login.vue'),
  meta: {
    requireAuth: false,
    beforeResolve: redirectLoginRoute,
    metaTags: {
      title: "Login",
    }
  }
}

const logout: IRouterConfig = {
  path: '/logout',
  name: 'Logout',
  component: () => import('@/views/public/Logout.vue'),
  meta: {
    requireAuth: false,
    metaTags: {
      title: "Logout",
    }
  }
}

const register: IRouterConfig = {
  path: '/register',
  name: '/Register',
  component: () => import('@/views/public/Register.vue'),
  meta: {
    requireAuth: false,
    beforeResolve: redirectLoginRoute,
    metaTags: {
      title: "Register",
    }
  }
}

const welcome: IRouterConfig = {
  name: "register-welcome",
  path: '/register/welcome',
  component: () => import('@/views/public/Welcome.vue'),
  meta: {
    requireAuth: false,
    beforeResolve: redirectLoginRoute,
    metaTags: {
      title: "Welcome",
    }
  },
  props: true
}

const loginMfa: IRouterConfig = {
  path: '/mfa',
  name: 'LoginVerifyRequest',
  component: () => import('@/views/public/LoginAuthenticate.vue'),
  meta: {
    requireAuth: false,
    beforeResolve: redirectMfaRoute,
    metaTags: {
      title: "MFA Request",
    }
  }
}

const loginMfaVerify: IRouterConfig = {
  path: '/mfa/verify',
  // name: 'LoginVerifyAuthorize',
  component: () => import('@/views/public/LoginAuthenticateVerify.vue'),
  meta: {
    requireAuth: false,
    beforeResolve: redirectMfaVerify,
    metaTags: {
      title: "MFA Verify",
    }
  },
  props: true,
  name: "mfa-verify"
}

const passwordReset: IRouterConfig = {
  path: '/password-reset',
  name: 'PasswordReset',
  component: () => import('@/views/public/PasswordReset.vue'),
  meta: {
    requireAuth: false,
    metaTags: {
      title: "Reset Password",
    }
  }
}

const claimAccount: IRouterConfig = {
  path: '/claim-account',
  name: 'ClaimAccount',
  component: () => import('@/views/public/ClaimAccount.vue'),
  meta: {
    requireAuth: false,
    metaTags: {
      title: "Claim Account",
    }
  }
}

const claimAccountRequest: IRouterConfig = {
  path: '/claim',
  name: 'ClaimAccountRequest',
  component: () => import('@/views/public/ClaimAccountRequest.vue'),
  meta: {
    requireAuth: false,
    metaTags: {
      title: "Claim Account Request",
    }
  }
}

const notAuthorized: IRouterConfig = {
  path: '/not-authorized',
  name: 'NotAuthorized',
  component: () =>
    import(/* webpackChunkName: "notAuthorized" */ '@/views/error/401.vue'),
  meta: {
    requireAuth: false,
    metaTags: {
      title: "Not Authorized",
    }
  }
}

const confirmAccount: IRouterConfig = {
  path: '/confirm-account',
  name: 'ConfirmAccount',
  component: () => import('@/views/public/ConfirmAccount.vue'),
  meta: {
    requireAuth: false,
    metaTags: {
      title: "Confirm Account",
    }
  }
}



const publicRoutes: RouteConfig[] = [login, logout, loginMfa, loginMfaVerify, passwordReset, claimAccount, notAuthorized, confirmAccount, claimAccountRequest, register, welcome]

export default publicRoutes
