var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingVisibilityCheck,
          expression: "loadingVisibilityCheck"
        }
      ],
      class: _vm.pageClass,
      attrs: { id: "app" }
    },
    [
      _c("vue-progress-bar"),
      !_vm.$_isLoggedIn ||
      _vm.currentRoutePath === "/login" ||
      _vm.currentRoutePath === "/logout" ||
      _vm.currentRoutePath === "/mfa" ||
      _vm.currentRoutePath === "/mfa/verify"
        ? _c("router-view", { staticClass: "relative h-screen flex flex-col" })
        : _c(_vm.viewComponent, {
            tag: "component",
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function() {
                  return [
                    _c("router-view", { on: { scrollTop: _vm.scrollAppToTop } })
                  ]
                },
                proxy: true
              }
            ])
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }