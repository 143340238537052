import { RouteConfig } from "vue-router";

const userRoutes: RouteConfig[] = [
  {
    path: "/dashboard",
    name: "UserDashboard",
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "@/views/user/dashboard/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard",
      requireAuth: true,
      allowedRoles: ["user"],
      metaTags: {
        title: "Dashboard",
      }
    }
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () => import("@/views/user/Profile.vue"),
    meta: {
      pageTitle: "Profile",
      requireAuth: true,
      allowedRoles: ["manager", "admin", "user"],
      metaTags: {
        title: "Profile",
      }
    }
  }
];


export default userRoutes;
