var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col justify-center items-center h-96" },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: true,
              expression: "true"
            }
          ],
          attrs: { "element-loading-background": "transparent" }
        },
        [
          _vm.imageLink
            ? _c("img", {
                staticClass: "mb-5 opacity-50",
                class: _vm.imageClasses,
                attrs: { src: _vm.imageLink, width: _vm.imageWidth }
              })
            : _vm._e()
        ]
      ),
      _c(
        "div",
        {
          staticClass: "pt-3 pb-1 text-center font-bold text-sm leading-tight",
          class: _vm.$_css_SecondaryTextColor
        },
        [_vm._v(_vm._s(_vm.messageText))]
      ),
      _c(
        "div",
        {
          staticClass: "pb-5 text-center leading-tight text-sm opacity-80",
          class: _vm.$_css_SecondaryTextColor
        },
        [_vm._v(_vm._s(_vm.description))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }