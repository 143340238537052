
import Vue from "vue";
import { get } from "lodash";

export default Vue.extend({
  props: {
    columnKey: {
      type: String,
      default: "",
    },
    column: {
      type: Object,
      default() {
        return {};
      },
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    columnClassList(): string[] {
      const { $_css_PrimaryTextColor } = this;
      const baseClasses = [
        "border-t",
        "border-gray-200 dark:border-gray-900",
        "px-3",
        "py-3.5",
        $_css_PrimaryTextColor,
      ];
      const { column } = this;
      if (column.hideOnMobile) {
        baseClasses.push("lg:table-cell");
        baseClasses.push("hidden");
      }
      return baseClasses;
    },
    valueFormatted(): any {
      const { formatDataValue } = this.column;
      const value = get(this.row, this.columnKey);
      const formatted = formatDataValue(value, this.row);
      return formatted;
    },
    valueComponent(): any {
      const { formatDataComponent } = this.column;
      return formatDataComponent;
    },
    isVueComponent(): any {
      const { formatDataComponent } = this.column;
      return !!formatDataComponent;
    },
  },
});
