var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative h-screen flex flex-col" },
    [
      _c(
        "div",
        {
          staticClass: "bg-white dark:bg-gray-800 shadow-sm relative z-50",
          attrs: { id: "header-outer" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "h-16 flex justify-between items-center w-full mx-auto max-w-7xl px-4"
            },
            [
              _c(
                "div",
                {},
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _c("img", {
                      staticClass: "mx-auto h-12 w-auto",
                      attrs: {
                        src:
                          "https://www.tridelta.org/wp-content/themes/tridelta/images/logo.png",
                        alt: "Workflow"
                      }
                    })
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "select-none cursor-pointer" }, [
                _c(
                  "div",
                  {
                    staticClass: "block sm:hidden",
                    on: {
                      click: function($event) {
                        _vm.showMenuModal = !_vm.showMenuModal
                      }
                    }
                  },
                  [
                    !_vm.showMenuModal
                      ? _c(
                          "svg",
                          {
                            staticClass: "h-6 w-6",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor",
                              "stroke-width": "2"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M4 6h16M4 12h16M4 18h16"
                              }
                            })
                          ]
                        )
                      : _c(
                          "svg",
                          {
                            staticClass: "h-6 w-6",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              stroke: "currentColor",
                              "stroke-width": "2"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M6 18L18 6M6 6l12 12"
                              }
                            })
                          ]
                        )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "hidden sm:flex" },
                  [
                    _c("WebAppMenu", {
                      staticClass: "flex items-center space-x-4 h-16 w-full"
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ]
      ),
      _vm.currentPageTitle
        ? _c("header", { staticClass: "bg-white dark:bg-gray-900 shadow-sm" }, [
            _c(
              "div",
              {
                staticClass:
                  "w-full max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center"
              },
              [
                _c(
                  "h1",
                  {
                    staticClass:
                      "text-lg leading-6 font-semibold text-gray-900 dark:text-gray-200"
                  },
                  [_vm._v(_vm._s(_vm.currentPageTitle))]
                )
              ]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "relative-z-5", attrs: { id: "body-outer" } }, [
        _c(
          "div",
          { staticClass: "w-full mx-auto max-w-7xl px-4 mt-8" },
          [_vm._t("body")],
          2
        )
      ]),
      _c(
        "div",
        {
          staticClass: "bg-gray-700 dark:bg-gray-900 mt-auto",
          attrs: { id: "footer-outer" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full h-12 mx-auto max-w-7xl px-4 flex justify-between items-center"
            },
            [
              _c("div", { staticClass: "text-white" }, [
                _vm._v(
                  "© " +
                    _vm._s(new Date().getFullYear()) +
                    " Infonaligy Partners"
                )
              ]),
              _c("el-switch", {
                model: {
                  value: _vm.darkMode,
                  callback: function($$v) {
                    _vm.darkMode = $$v
                  },
                  expression: "darkMode"
                }
              })
            ],
            1
          )
        ]
      ),
      _c("transition", { attrs: { name: "slide-menu" } }, [
        _vm.showMenuModal
          ? _c(
              "div",
              {
                staticClass:
                  "absolute top-0 left-0 w-full h-full transition-transform",
                class: _vm.showMenuModal
                  ? "z-40 translate-y-none bg-white translate-x-6"
                  : "z-0 -translate-y-full"
              },
              [
                _c(
                  "div",
                  { staticClass: "pt-20 px-3" },
                  [
                    _c("WebAppMenu", {
                      staticClass: "flex flex-col items-start space-y-4",
                      attrs: {
                        menuItemClasses: "w-full py-4",
                        outerMenuClass: "w-full"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }