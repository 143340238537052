import defaultsDeep from "lodash/defaultsDeep"
import cloneDeep from "lodash/cloneDeep"
import { baseCommunication } from "@/helpers/defaultObjects"
import { ICommunication } from "@/store/modules/communication/types";

export function getCommunicationDefaults(
  communication: Partial<ICommunication>
): Partial<ICommunication> {
  return defaultsDeep(
    cloneDeep(communication),
    Object.assign({}, baseCommunication())
  );
}