import BaseService from "@/services/base.service";
import { ResponseWrapper } from "@/services/response_wrapper";
import {
  LoginRequest,
  PasswordResetRequest,
  PasswordUpdateRequest,
  IRegistrationDetails,
  verifyMfaRequest
} from "@/types";

class AuthService extends BaseService {
  async login(credentials: LoginRequest): Promise<any> {
    const path = `auth/login`;
    return await this.request({
      method: "post",
      url: path,
      data: {...credentials }
    });
  }

  async register(registrationDetails: IRegistrationDetails): Promise<ResponseWrapper> {
    const path = `register`;
    return await this.request({
      method: "post",
      url: path,
      data: {...registrationDetails }
    });
  }
  async checkEmailInUse(email: string): Promise<ResponseWrapper> {
    const path = `auth/check-email`;
    return await this.request({
      method: "post",
      url: path,
      data: { email }
    });
  }

  async checkMfaRequest(): Promise<ResponseWrapper> {
    const path = `auth/mfa/check`;
    return await this.request(
      {
        method: "get",
        url: path,
      },
      { useToken: "auth_mfa_request" }
    );
  }

  async requestMfaMethod(mfaMethod: string): Promise<ResponseWrapper> {
    const path = `auth/mfa/request`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: {...{ mfaMethod } }
      },
      { useToken: "auth_mfa_request" }
    );
  }

  async checkMfaVerify(): Promise<ResponseWrapper> {
    const path = `auth/mfa/check`;
    return await this.request(
      {
        method: "get",
        url: path,
      },
      { useToken: "auth_mfa_verify" }
    );
  }
  
  async verifyMfaCode(payload: verifyMfaRequest): Promise<ResponseWrapper> {
    const path = `auth/mfa/verify`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: payload
      },
      { useToken: "auth_mfa_verify" }
    );
  }

  async passwordReset(
    credentials: PasswordResetRequest
  ): Promise<ResponseWrapper> {
    const path = `auth/password-reset/request`;
    return await this.request({
      method: "post",
      url: path,
      data: { ...credentials }
    });
  }

  async canResetPassword(): Promise<ResponseWrapper> {
    const path = `auth/password-reset/validate`;
    return await this.request(
      {
        method: "post",
        url: path
      },
      { useToken: "password_reset" }
    );
  }

  async updatePassword(
    credentials: PasswordUpdateRequest
  ): Promise<ResponseWrapper> {
    const path = `auth/reset-password`;
    return await this.request(
      {
        method: "post",
        url: path,
        data: credentials
      },
      { useToken: "password_reset" }
    );
  }

  async logout(): Promise<ResponseWrapper> {
    const path = `auth/logout`;
    return await this.request(
      {
        method: "post",
        url: path
      },
      { useToken: "access" }
    );
  }

  async check(): Promise<ResponseWrapper> {
    const path = `auth/check`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }

  async refreshToken(): Promise<ResponseWrapper> {
    const path = `auth/refresh-token`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }

  async requestClaimAccount( credentials: Partial<PasswordResetRequest>): Promise<ResponseWrapper> {
    const path = `auth/account/claim/request`;
    return await this.request({
      method: "post",
      url: path,
      data: {...credentials }
    });
  }

  async claimAccount(): Promise<ResponseWrapper> {
    const path = `auth/account/claim/validate`;
    return await this.request({
      method: "post",
      url: path,
    }, { useToken: "claim_account" });
  }

  async confirmAccount(): Promise<ResponseWrapper> {
    const path = `auth/account/confirm`;
    return await this.request({
      method: "post",
      url: path,
    }, { useToken: "account_confirmation" });
  }

  async requestConfirmAccount(credentials: Partial<PasswordResetRequest>): Promise<ResponseWrapper> {
    const path = `auth/account/confirm/request`;
    return await this.request({
      method: "post",
      url: path,
      data: credentials
    });
  }

  async getAdminPreviewToken(): Promise<ResponseWrapper> {
    const path = `auth/admin-preview`;
    return await this.request(
      {
        method: "get",
        url: path
      },
      { useToken: "access" }
    );
  }
}

export default new AuthService();