var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "td",
    { class: _vm.columnClassList },
    [
      _vm.isVueComponent
        ? _c(_vm.valueComponent, {
            tag: "component",
            attrs: {
              valueFormatted: _vm.valueFormatted,
              columnKey: _vm.columnKey,
              column: _vm.column,
              row: _vm.row
            }
          })
        : _c("span", {
            staticClass: "break-normal",
            domProps: { innerHTML: _vm._s(_vm.valueFormatted) }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }