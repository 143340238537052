import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import {
  getCurrentUser,
  canAccessRoute,
  setPageMeta,
  routerBeforeResolve
} from './middleware'

const {
  NODE_ENV = "",
} = process.env;

if (NODE_ENV !== 'test') {
  Vue.use(Router)
}

const router = new Router({
  mode: 'history',
  routes: [
    ...routes
  ]
})

// This has to run first
router.beforeEach(getCurrentUser)
router.beforeEach(canAccessRoute)
router.beforeEach(setPageMeta)
router.beforeResolve(routerBeforeResolve)

export default router
