<template web>
<div class="flex flex-col justify-center items-center h-96">
  <span v-loading="true" element-loading-background="transparent"><img v-if="imageLink" class="mb-5 opacity-50" :src="imageLink" :width="imageWidth" :class="imageClasses" /></span>
  <div class="pt-3 pb-1 text-center font-bold text-sm leading-tight" :class="$_css_SecondaryTextColor">{{messageText}}</div>
  <div class="pb-5 text-center leading-tight text-sm opacity-80" :class="$_css_SecondaryTextColor">{{description}}</div>
</div>
</template>


<script>
export default {
  name: "LoadingViewPlaceholder",
  props: {
    imageLink: {
      type: String,
      default: "",
    },
    iconClass: {
      type: String,
      default: "",
    },
    messageText: {
      type: String,
      default: "Loading",
    },
    description: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonAltText: {
      type: String,
      default: "",
    },
    showButtonAlt: {
      type: Boolean,
      default: false,
    },
    imageWidth: {
      type: String,
      default: "150",
    },
    imageClasses: {
      type: String,
      default: "",
    }
  },
  methods: {
    buttonAction() {
      this.$emit('buttonAction')
    },
    buttonAltAction() {
      this.$emit('buttonAltAction')
    },
    PulseImage({object}){
      const animation1 = object.createAnimation({scale: { x: 1.02, y: 1.02}, duration: 80})
      const animation2 = object.createAnimation({scale: { x: 1, y: 1 }, duration: 100})
      animation1
      .play()
      .then(() => animation2.play())
    }
  },
};
</script>
