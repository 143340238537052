import get from "lodash/get";

const pluginNotify = {
  install(Vue: any) {
    Vue.prototype.$appNotifyError = async function (message = "", description = "", opts = {}) {
      return this.$appNotify({message, description}, 'error', opts)
    },
    Vue.prototype.$appNotifySuccess = async function (message = "", description = "", opts = {}) {
      return this.$appNotify({message, description}, 'success', opts)
    },
    Vue.prototype.$appNotify = async function ({message = "", description = ""}, type = 'info', opts = {}) {
      if(!message) return;
      if(!['info', 'success', 'warning', 'error'].includes(type)) return
  
      const defaults = {
        position: "top-center",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: false,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      }

      const typeFunctionMap = {
        info: this.$toast,
        success: this.$toast.success,
        warning: this.$toast.warning,
        error: this.$toast.error,
      }

      const alertFunction = get(typeFunctionMap, type, typeFunctionMap['info'])
      if(typeof alertFunction === 'function'){
        alertFunction({
          name: "AlertDefaultElement",
          template: `<div class="container">
          <div class="text-lg font-medium">${message}</div>
          <div v-if="${description}">${description}</div>
        </div>`,
        }, Object.assign({}, defaults, opts ));
      }
    }
  },
}

export default pluginNotify
