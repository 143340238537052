
import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import { IAppTheme , IAppThemeSettings} from "@/store/modules/app/types"

export const appBaseSettings = {
    views: {
      login: {
        backgroundImage: "https://ia-core-public.s3-us-west-2.amazonaws.com/login-bg1.1d1a382e.jpeg"
      }
    }
}

export const appBaseTheme = {
  views: {
    login: {
      backgroundImage: "https://ia-core-public.s3-us-west-2.amazonaws.com/login-bg1.1d1a382e.jpeg"
    }
  },
  logoUrl: "",
  logoIconUrl: "",
  menuPosition: "",
  menuCollapse: false
}


export function getAppThemeAndSettingsDefaults(appSettings: any): {theme: IAppTheme, settings: IAppThemeSettings} {
  const {theme = {}, settings = {}} = appSettings
  return {
    theme: defaultsDeep(cloneDeep(theme), appBaseTheme),
    settings: defaultsDeep(cloneDeep(settings), appBaseSettings)
  }
}