
import Vue from "vue";
// import { throttle } from "lodash";
import { appMapGetters } from "@/store/modules/app";

export default Vue.extend({
  name: "PageTopButtons",
  // mounted() {
  //   console.log("mounted");
  //   // const debounced = throttle(this.handleScroll, 1000) as any;
  //   window.addEventListener("scroll", this.handleScroll);
  // },
  // beforeDestroy() {
  //   const debounced = throttle(this.handleScroll, 1000) as any;
  //   window.removeEventListener("scroll", debounced);
  // },
  data() {
    return {};
  },
  methods: {
    handleScroll(data: any) {
      console.log("scrolling", data);
    }
  },
  computed: {
    ...appMapGetters(["getScrollTop"])
  }
});
