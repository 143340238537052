import { DefineMutations } from "vuex-type-helper";
import { AppState, AppMutations, IAppWindowSize , SetAppState} from "./types";
import { getAppThemeAndSettingsDefaults} from "@/helpers/appCoreBaseSettings"

import has from "lodash/has";

export const mutations: DefineMutations<AppMutations, AppState> = {
  SET_STATE(state: AppState, { key, value }: SetAppState): void {
    if (has(state, key)) {
      //@ts-ignore
      state[key] = value;
    }
  },
  SET_WINDOW(state: AppState, windowSize: IAppWindowSize): void {
    state.window = windowSize;
  },
  SET_WINDOW_VISIBILITY(state: AppState, isVisible: boolean): void {
    state.windowVisible = isVisible;
  },
  SET_PAGE_TITLE(state: AppState, title: string): void {
    state.pageTitle = title;
  },
  TOGGLE_SIDEBAR(state: AppState, force?: boolean): void {
    const toVal = typeof force === "boolean" ? force : !state.showSidebar
    state.showSidebar = toVal;
  },
  SET_SCROLL_TOP(state: AppState, num: number): void {
    state.scrollTop = num;
  },
  SET_INIT_DATA(state: AppState, data: any): void {
    const {settings, theme} = getAppThemeAndSettingsDefaults(data)
    state.settings = settings;
    state.theme = theme;
  },
  SET_HIDE_NAVIGATION(state: AppState, hide: boolean): void {
    state.hideNavigation = hide;
  },
	SET_UNIVERSAL_LINK(state: AppState, universal_link: any): void {
		state.universalLink = universal_link;
	},
	CLEAR_UNIVERSAL_LINKS(state: AppState): void {
		state.universalLink = null;
	},
	SET_PUSH_DATA(state: AppState, push_notification_data: any): void {
		state.push_data = push_notification_data;
	},
	CLEAR_PUSH_DATA(state: AppState): void {
		state.push_data = null;
	},
  SET_DARK_MODE(state: AppState, isDarkMode: boolean): void {
    state.isDarkMode = isDarkMode;
  },
  SET_SCREEN(state: AppState, currentScreen: string): void {
    state.currentScreen = currentScreen;
  },
};
