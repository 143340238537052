import { RouteConfig } from "vue-router";

const communicationRoutes: RouteConfig[] = [
  {
    path: "/communications",
    component: () => import("@/views/shared/communication/ListCommunication/ListCommunication.vue"),
    meta: {
      pageTitle: "Communications",
      requireAuth: true,
      allowedRoles: ["admin", "manager", "user"],
      metaTags: {
        title: "Communications",
      },
    },
  },
  {
    path: "/communications/new",
    component: () => import("@/views/shared/communication/SingleCommunication/AddCommunication.vue"),
    meta: {
      pageTitle: "Create Communication",
      requireAuth: true,
      allowedRoles: ["manager"]
    },
  },
  {
    path: "/communications/:communicationId",
    component: () =>
      import("@/views/shared/communication/SingleCommunication/Edit.vue"),
    meta: {
      breadcrumbs: [
        {
          link: "/communications",
          title: "Communications"
        }
      ],
      pageTitle: "Communication",
      requireAuth: true,
      allowedRoles: ["manager"]
    }
  },
  {
    path: "/communications/:communicationId/completed",
    component: () =>
      import(
        "@/views/shared/communication/SingleCommunicationDispatched/CommunicationDispatched.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          link: "/communications",
          title: "Communications"
        }
      ],
      pageTitle: "Communication",
      requireAuth: true,
      allowedRoles: ["manager"],
    }
  }
  // {
  //   path: "/communications/:communication_id",
  //   component: () => import("@/views/shared/communication/SingleCommunication/Edit.vue"),
  //   meta: {
  //     pageTitle: "Communication",
  //     requireAuth: true,
  //     allowedRoles: ["admin", "manager", "user"]
  //   }
  // }
];

export default communicationRoutes;
