<template>
  <div
    class="menu-item-wrap relative h-full flex items-center"
    :class="outerMenuClass"
    @mouseenter="menuItemMouseEnter"
    @mouseleave="menuItemMouseLeave"
  >
    <router-link :to="to" custom v-slot="{ isActive, href, navigate }">
      <!-- <div class="menu-icon">
        <font-awesome-icon :icon="['fal', icon]" />
      </div> -->
      <transition name="fade">
          <a :class="menuItemClassName(isActive)" :href="href" @click="navigate">{{ text }}</a>
          <!-- <div class="small">{{ description }}</div> -->

      </transition>
    </router-link>
    <transition name="fade-down">
      <div
        class="submenu-items absolute right-0 top-full text-xs w-48 bg-white text-right px-4 py-4 shadow-md"
        v-if="itemHover && subLinks && subLinks.length"
      >
        <router-link
          v-for="(sublink, index) in subLinks"
          :to="sublink.to"
          :key="index"
          class="block mb-1 py-3"
          >{{ sublink.text }}</router-link
        >
      </div>
    </transition>
  </div>
</template>

<script>
import { appMapState } from "@/store/modules/app";

import Vue from "vue";
import smoothReflow from "vue-smooth-reflow";
export default Vue.extend({
  name: "MenuItem",
  mixins: [smoothReflow],
  props: {
    to: {
      type: String,
      default: "",
      required: true
    },
    text: {
      type: String,
      default: "",
      required: true
    },
    icon: {
      type: String,
      default: "",
      required: true
    },
    description: {
      type: String,
      default: "",
      required: false
    },
    showNotification: {
      type: Boolean,
      default: false,
      required: false
    },
    subLinks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    menuItemClasses: {
      type: String,
      default: "",
    },
    outerMenuClass: {
      type: String,
      default: "",
    },

  },
  data() {
    return {
      itemHover: false,
      showMenuTimer: null
    };
  },
  mounted() {
    this.$smoothReflow({
      el: ".menu-item-wrap"
    });
  },
  methods: {
    menuItemMouseEnter() {
      // this.itemHover = true;
      // this.showMenuTimer = setTimeout(() => {
      this.itemHover = true;
      // }, 160);
    },
    menuItemMouseLeave() {
      // clearTimeout(this.showMenuTimer);
      this.itemHover = false;
    },
    menuItemClassName(isActive) {
      const classNames = ["px-3", "py-1", "rounded-md", "font-medium", ];
      if (this.showNotification) {
        classNames.push("has-notification");
      }

      if(isActive){

        if(this.$_isDarkMode){
          classNames.push("bg-gray-900");
          classNames.push("text-gray-200");
        }else{
          classNames.push("bg-gray-100");
          classNames.push("text-gray-700");
        }
  
      }else{
          classNames.push(this.$_css_PrimaryTextColor);
          classNames.push("hover:text-red-900");
      }

      return `${classNames.join(" ")} ${this.menuItemClasses}`;
    }
  },
  computed: {
    ...appMapState(["showSidebar"])
  }
});
</script>

<style scoped>


</style>