import {
    TenantActions,
    TenantState,
    TenantMutations,
    TenantGetters,
    ITenantUpdatePayload
  } from "./types";
  import { DefineActions } from "vuex-type-helper";
  import * as types from "@/store/mutation-types";
  import TenantService from "@/services/tenant.service";
  import { ITenant } from "../../../types/interfaces";
  import {
    GenericCreateOne,
    GenericDeleteOne,
    GenericGetMany,
    GenericGetOne,
    GenericUpdateOne,
  } from "@/store/helpers/actions";  

  export const actions: DefineActions<
  TenantActions,
  TenantState,
  TenantMutations,
  TenantGetters
> = {
  createTenant: GenericCreateOne<
  TenantState,
  TenantGetters,
  TenantActions,
  TenantMutations,
  ITenant
>(TenantService, types.SET_USER as any),

getTenants: GenericGetMany<TenantState, TenantGetters, TenantActions, TenantMutations>(
  TenantService,
  "tenants",
  "SET_TENANT"
),

getTenant: GenericGetOne<TenantState, TenantGetters, TenantActions, TenantMutations>(
  TenantService,
  types.SET_TENANT
),

deleteTenant: GenericDeleteOne<
TenantState, TenantGetters, TenantActions, TenantMutations
>(TenantService, types.DELETE_TENANT),
updateTenant: GenericUpdateOne<
TenantState, TenantGetters, TenantActions, TenantMutations,
ITenantUpdatePayload
>(TenantService, types.SET_TENANT),

 
    async getTenantStats({ commit }, id): Promise<ITenant> {
      try {
        commit(types.SET_STATE, { key: "loadingGet", value: true });
        const tenant = await TenantService.getStats(id);
        const tenantData: ITenant = tenant.data;
        // commit(types.SET_TENANT, tenantData);
        commit(types.SET_STATE, { key: "lastUpdate", value: new Date() });
        return tenantData;
      } finally {
        commit(types.SET_STATE, { key: "loadingGet", value: false });
      }
    },
  };
  