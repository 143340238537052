import BaseCollectionService  from './base.collection.service';
import { ResponseWrapper } from "@/services/response_wrapper";

class TenantService extends BaseCollectionService{
    constructor(){
      super('tenants')
    }

  public async getStats(id: string): Promise<ResponseWrapper> {
    const path = `${this.url}/${id}/stats`
    return await this.request({
      method: 'get',
      url: path
    }, { useToken: "access" })
  }

}

export default new TenantService()