
import { authMapGetters, authMapActions } from "@/store/modules/auth";
import {
  appMapActions,
  appMapGetters,
  appMapState,
  appMapMutations,
} from "@/store/modules/app";
import {userMapActions} from "@/store/modules/users"


import { kebabCase, throttle } from "lodash";
import Vue from "vue";
import { FEATURE_FLAG_QUOTES } from "./helpers/features";
import WebBaseViewWrap from "@/views/core/WebBaseViewWrap.vue"

export default Vue.extend({
  name: "App",
  components: {
    WebBaseViewWrap,
  },
  data(): any {
    return {
      loadingVisibilityCheck: false,
    };
  },
  created(): void {
    this.$Progress.start();
    this.$router.beforeEach((to: any, from: any, next: any) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        (this.$Progress as any).parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });

    this.appHandleResize();
    const debounced = throttle(this.appHandleResize, 1000) as any;
    window.addEventListener("resize", debounced);
  },
  mounted(): void {
    const { appInnerView } = this.$refs as any;
    if (appInnerView) {
      const debounced = throttle(this.appHandleScroll, 200) as any;
      appInnerView.addEventListener("scroll", debounced);
    }
    document.addEventListener("visibilitychange", this.doVisibilityCheck);
  },
  destroyed() {
    const debounced = throttle(this.appHandleResize, 1000) as any;
    window.removeEventListener("resize", debounced);
    document.removeEventListener("visibilitychange", this.doVisibilityCheck);
  },
  computed: {
    ...authMapGetters(["getCurrentUser"]),
    ...appMapGetters(["getWindowSize"]),
    ...appMapState(["showSidebar", "windowVisible"]),
    viewComponent(){
      return WebBaseViewWrap
    },
    pageClass(): string {
      const { $_isDarkMode } = this;
      const backgroundClass = this.$_css_PrimaryBackgroundColor;
      const title = this.$appCurrentRoute.meta.pageTitle || "unknown";
      let pageClass = `page-${kebabCase(title)} ${backgroundClass}`;
      if( $_isDarkMode ){
        return `dark ${pageClass}`
      }
      return pageClass
    },
    currentRoutePath(): boolean {
      return this.$appCurrentRoute.path
    },
    isOnboarding(): boolean {
      return this.currentRoutePath.includes("onboarding");
    },
    quoteFeatureEnabled(): boolean {
      const { disabledFeatures } = this.getCurrentUser;
      if (disabledFeatures && Array.isArray(disabledFeatures)) {
        const check = disabledFeatures.find(
          (dsf: any) => dsf.key === FEATURE_FLAG_QUOTES
        );
        return !check;
      }
      return true;
    },
  },
  watch: {
    $appCurrentRoute: {
      deep: true,
      handler(route, previous = {}){
        const {name /*, meta, path */ } = route
        const {name: previousName  } = previous
        // console.log(meta, path, name, previousName)
        if (this.$_isLoggedIn && name && name !== previousName ) {
          this.makeScreenViewActivity({ screenName: name });
        }
      }
    },
    $_isLoggedIn(isLoggedIn: boolean): void {
      if (!isLoggedIn) {
        this.$socket.client.close();
        this.$router.replace("/login").catch(() => {});
      } else {
        this.$socket.client.open();
      }
    },
    $_isDarkMode: {
      immediate: true,
      handler(darkMode){
        if(darkMode){
          document.documentElement.classList.add('dark')
        }else{
          document.documentElement.classList.remove('dark')
        }
        if(this.$_isLoggedIn){
          this.updateUser({
            id: this.$_getCurrentUserId,
            update: { settings: { preferDarkMode: darkMode } }
          })
        }
      },
    },
    windowVisible(isWindowVisible: boolean, oldValue: boolean) {
      this.loadingVisibilityCheck = false;
      const { requireAuth } = this.$appCurrentRoute.meta;
      if (requireAuth && !oldValue && isWindowVisible) {
        this.loadingVisibilityCheck = true;
        console.log("window visibility changed, checking auth...");
        this.authCheckGetUser().finally(() => {
          this.loadingVisibilityCheck = false;
        });
      }
    }
  },
  methods: {
    ...userMapActions(["makeScreenViewActivity", "updateUser"]),
    ...authMapActions(["authCheckGetUser"]),
    ...appMapMutations(["SET_WINDOW_VISIBILITY"]),
    ...appMapActions([
      "appHandleResize",
      "appHandleScroll",
      "refreshItemStore",
    ]),
    scrollAppToTop(): void {
      const { appInnerView } = this.$refs as any;
      if (appInnerView) {
        appInnerView.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    doVisibilityCheck(): void {
      const { hidden } = document;
      this.SET_WINDOW_VISIBILITY(!hidden);
    }
  },
  provide(): any {
    return {
      investmentFeatureEnabled: Vue.observable(this.investmentFeatureEnabled),
    };
  },
});
