import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  DocumentsState,
  DocumentsMutations,
  StateKeys,
  IDocuments
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";

export const mutations: DefineMutations<DocumentsMutations, DocumentsState> = {
  [types.SET_STATE]: GenericSetState<DocumentsState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<DocumentsState>(),
  [types.SET_EDIT]: GenericSetEdit<DocumentsState, string | IDocuments>(
    "documents",
    (data: any) => ({ ...data })
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<
    DocumentsState,
    keyof IDocuments
  >(),
  [types.SET_ITEM]: GenericSetItem<DocumentsState, IDocuments>(
    "documents",
    (data: any) => ({ ...data })
  ), 
  [types.DELETE_ITEM]: GenericDeleteItem<DocumentsState>("documents", "_id"),
  [types.CLEAR_ITEM_STORE]: GenericClearStore<DocumentsState>(defaultState()),
};
