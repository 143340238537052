<template>
<div class="main-view">
  <div class="main-icon">
   <font-awesome-icon :icon="['fal', 'exclamation-circle']"/>
  </div>
  <h1 class="main-header">{{ title }}</h1>
  <h2 class="text-center">{{ subtitle }}</h2>
  <div class="text-lg">
    <router-link :to="actionRedirectPath"><i class="el-icon-back mr-2"></i>{{ actionButtonTitle }}</router-link>
  </div>
</div>
</template>

<script>
import Vue from "vue"
export default Vue.extend({
  props: {
    title: {
      type: String,
      required: false,
      default: "Ooops! The resource you are looking for cannot be found."
    }, 
    subtitle: {
      type: String,
      required: false,
      default: "You may have the wrong url. Check and try again later"
    },
    actionButtonTitle: {
      type: String, 
      required: false,
      default: "Back to homepage"
    },
    actionRedirectPath: {
      type: String,
      required: false,
      default: "/"
    },

  }
})
</script>
<style scoped>
.main-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77vh;
  width: 100%;
  flex-direction: column;
}

.main-icon {
  font-size: 121px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: tomato; */
  margin-bottom: 2rem;;
}
</style>