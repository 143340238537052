import { ActivityState } from "./types";

export const defaultState = (): ActivityState => {
  return {
    activities: [],
    loadingGet: false,
    loadingCreate: false,
    loadingDelete: false,
    loadingUpdate: false,
    lastUpdate: new Date(),
    activityLinks:{ }
  };
};

export const state = defaultState();
