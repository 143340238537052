import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { ResponseWrapper } from '@/services/response_wrapper'
import { ErrorWrapper } from '@/services/error_wrapper'
export const getAuthorizationBearerStringFor = (tokenKey: string) => {
  return `Bearer ${localStorage.getItem(tokenKey)}`;
};

export default class ExternalService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_SSO_HTTP_SERVER
    });
  }

  async request(
    axiosConfig: AxiosRequestConfig,
    options?: any
  ): Promise<ResponseWrapper> {
    try {
      if (options && options.useToken) {
        this.instance.defaults.headers[
          "Authorization"
        ] = getAuthorizationBearerStringFor(options.useToken);
      }
      if (options && options.contentType) {
        // headers: { "Content-Type":  }
        this.instance.defaults.headers["Content-Type"] = options.contentType;
      }
      const response = await this.instance.request(axiosConfig);
      return new ResponseWrapper(response);
    } catch (e: any) {
      throw new ErrorWrapper(e);
    }
  }
  // async requestNoWrappers(
  //   axiosConfig: AxiosRequestConfig,
  //   options?: any
  // ): Promise<AxiosResponse> {
  //   const http = new Http(options)
  //   const axios = http.getInstance()
  //   return axios.request(axiosConfig)
  // }

  getInstance(): AxiosInstance {
    return this.instance;
  }
}
