export const global = {
  saveResourceText: (resource: string) => `Save ${resource}`,
  createNewText: (resource: string) => `Create New ${resource}`,
  deleteResourceText: (resource: string) => `Delete ${resource}`,
  addResourceText: (resource: string) => `Add ${resource}`,
  assignResourceText: (resource: string) => `Assign ${resource}`,
  noResourceText: (resource: string) => `No ${resource}`,
  loadingResourceText: (resource: string) => `Loading ${resource}`,
  confirmResourceText: (resource: string) => `Confirm ${resource}`,
  clickResourceText: (resource: string) => `Click to ${resource}`,
  editResourceText: (resource: string) => `Edit ${resource}`,
  viewResourceText: (resource: string) => `View ${resource}`,
  unsavedChangesText: "You have unsaved changes.",
  unassignUserText: "Are you sure you want to unassign this user from this profile? The user will no longer have access to this profile, or any documents/investments associated with the profile.",
  unsavedChangesWarningText: "You have unsaved changes. Continue?",
  warningHeaderText: "Warning",
  loginLinkText: "<router-link to='/login' class='text-blue-500'>Login</router-link>",
  updatePasswordText: "Enter your new password below",
  updatePasswordButtonText: "Update Password",
  updatePasswordHeader: "Update Password",
  updatePasswordSuccess: "Password update successful. Click the link below to login.",
  adminDashboardMessage: "Hello!",
  userDashboardMessage: "Hello!",
  checkLaterText: "Check back later.",
  noResultsQueryText: "No results matched your query.",
  tryLaterText: "Please try again",
  verifyText: "Click here to verify",
  enterCodeText: "Click here to enter code",
  resendCodeText: (minutes: number) => `You can resend code in ${minutes} minutes`,
  verificationCodeSentText: "A verification code has been sent to your phone. Please enter it below",
  sendverificationCodeText: "To verify your phone number, we are going to send a code via sms to:",
  errorCreating: (resource: string) => `Error creating ${resource}`,
  errorUpdating: (resource: string) => `Error updating ${resource}`,
};
