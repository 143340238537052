import { TenantState } from "./types";

export const defaultState = (): TenantState => {
  return {
    tenants: [],
    editing: {},
    loadingGet: false,
    loadingCreate: false,
    loadingDelete: false,
    loadingUpdate: false,
    lastUpdate: new Date(),
    makingApiRequest: false,
    links:{},
  };
};

export const state = defaultState();
