import Vue from "vue"
export default () => [
  {
    to: "/manager/tenants",
    text: 'Tenants',
    description: "manage tenants",
    icon: "users-cog",
  }, 
  {
    to: "/admin/users",
    text: Vue.prototype.__getText('menu', 'usersLinkText'),
    description: "manage users",
    icon: "users-cog",
  }, 
  {
    to: "/messages",
    text: 'Messages',
    description: "manage messages",
    icon: "envelope"
  }, 
  {
    to: "/communications",
    text: 'Communications',
    description: "manage communications",
    icon: "paper-plane"
  }, 
]