export const LOGIN = 'LOGIN'
export const DO_MFA = `DO_MFA`
export const LOGOUT = 'LOGOUT'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const SET_ACTIVITIES = 'SET_ACTIVITIES'
export const SET_ACTIVITY_STATE = 'SET_ACTIVITY_STATE'
export const SET_ACTIVITY_LINKS = "SET_ACTIVITY_LINKS"
export const DROP_ACTIVITIES = "DROP_ACTIVITIES"


export const SET_COMMUNICATION_STATE = "SET_COMMUNICATION_STATE"
export const SET_COMMUNICATIONS = "SET_COMMUNICATIONS"
export const REMOVE_COMMUNICATION = "REMOVE_COMMUNICATION"
export const DROP_COMMUNICATIONS = "DROP_COMMUNICATIONS"
export const SET_COMMUNICATION_LINKS = "SET_COMMUNICATION_LINKS"
export const SET_EDIT_COMMUNICATION = "SET_EDIT_COMMUNICATION"
export const SET_COMMUNICATION_EDIT_FIELD = "SET_COMMUNICATION_EDIT_FIELD"
export const SET_COMMUNICATION_METRICS = "SET_COMMUNICATION_METRICS"

export const SET_DOCUMENT_STATE = 'SET_DOCUMENT_STATE'
export const SET_DOCUMENT = 'SET_DOCUMENT'
export const SET_DOCUMENT_LINKS = 'SET_DOCUMENT_LINKS'
export const DROP_DOCUMENTS = "DROP_DOCUMENTS"
export const REMOVE_DOCUMENT = `REMOVE_DOCUMENT`
export const SET_EDIT_DOCUMENT = `SET_EDIT_DOCUMENT`
export const SET_DOCUMENT_EDIT_FIELD = `SET_DOCUMENT_EDIT_FIELD`

export const SET_USER_STATE = 'SET_USER_STATE'
export const SET_USER = 'SET_USER'
export const DELETE_USER = 'DELETE_USER'
export const DROP_USERS = "DROP_USERS"
export const SET_USER_LINKS = "SET_USER_LINKS"
export const SET_EDIT_USER = "SET_EDIT_USER"
export const SET_USER_EDIT_FIELD = `SET_USER_EDIT_FIELD`
export const CLEAR_USER_STORE = `CLEAR_USER_STORE`

//SPECIAL
export const REMOVE_ALL_USERS = "REMOVE_ALL_USERS"


export const SCROLL_TO_TOP = "SCROLL_TO_TOP"

export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_APP_SETTINGS_STATE = "SET_APP_SETTINGS_STATE";
export const REMOVE_APP_SETTINGS = "REMOVE_APP_SETTINGS";
export const SET_APP_SETTINGS_LINKS = "SET_APP_SETTINGS_LINKS";
export const SET_EDIT_APP_SETTINGS = "SET_EDIT_APP_SETTINGS";
export const DROP_APP_SETTINGS = "DROP_APP_SETTINGS";
export const SET_APP_SETTINGS_EDIT_FIELD = `SET_APP_SETTINGS_EDIT_FIELD`;

export const SET_STATE = "SET_STATE";
export const SET_STATES = "SET_STATES";

export const SET_TENANT_STATE = 'SET_TENANT_STATE'
export const SET_TENANT = 'SET_TENANT'
export const DELETE_TENANT = 'DELETE_TENANT'
export const DROP_TENANTS = "DROP_TENANTS"
export const SET_TENANT_LINKS = "SET_TENANT_LINKS"
export const SET_EDIT_TENANT= "SET_EDIT_TENANT"
export const SET_TENANT_EDIT_FIELD = `SET_TENANT_EDIT_FIELD`

export const SET_MESSAGE = 'SET_MESSAGE'
export const DELETE_MESSAGE = 'DELETE_MESSAGE'
export const SET_EDIT_MESSAGE = "SET_EDIT_MESSAGE"
export const SET_MESSAGE_EDIT_FIELD = `SET_MESSAGE_EDIT_FIELD`
export const CLEAR_MESSAGE_STORE = `CLEAR_MESSAGE_STORE`

export const SET_EDIT_FIELD = "SET_EDIT_FIELD";
export const SET_EDIT = "SET_EDIT";
export const SET_ITEM = "SET_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const CLEAR_COMMUNICATION_STORE = "CLEAR_COMMUNICATION_STORE";
export const CLEAR_ITEM_STORE = "CLEAR_ITEM_STORE"

export const SET_UNIVERSAL_LINK = "SET_UNIVERSAL_LINK";
export const CLEAR_UNIVERSAL_LINKS = "CLEAR_UNIVERSAL_LINKS";
export const SET_PUSH_DATA = "SET_PUSH_DATA";
export const CLEAR_PUSH_DATA = "CLEAR_PUSH_DATA";
