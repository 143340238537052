import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  MessagesState,
  MessagesGetters,
  MessagesMutations,
  MessagesActions
} from "./types";

const namespaced = true;
export const namespace = "messages";

export const {
  mapState: messagesMapState,
  mapGetters: messagesMapGetters,
  mapMutations: messagesMapMutations,
  mapActions: messagesMapActions
} = createNamespacedHelpers<
  MessagesState,
  MessagesGetters,
  MessagesMutations,
  MessagesActions
>(namespace);

export const messages: Module<MessagesState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
