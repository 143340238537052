import { faTimes } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faTimes'
import { faSpinner } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faSpinner'
import { faCheck } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faCheck'
import { faAsterisk } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faAsterisk'
import { faUserCircle } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faUserCircle'
import { faFolder } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFolder'
import { faFolders } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFolders'
import { faFilePdf } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFilePdf'
import { faFileImage } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFileImage';
import { faFileWord } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFileWord';
import { faFileExcel } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFileExcel';
import { faFile } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFile';
import { faEnvelope } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faEnvelope'
import { faAlignCenter } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faAlignCenter'
import { faUsersCog } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faUsersCog'
import { faPaperPlane } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faPaperPlane'
import { faCloudUpload } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faCloudUpload'
import { faFileUpload } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFileUpload'
import { faBars } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faBars'
import { faBuilding } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faBuilding'
import { faChevronLeft } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faChevronLeft'
import { faChevronRight } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronUp } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faChevronUp'
import { faChevronDown } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faChevronDown'
import { faHome } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faHome'

import { faFileAlt } from "@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFileAlt"
import { faTrashAlt } from "@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faTrashAlt"
import { faFileExclamation } from "@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFileExclamation"
import { faCog } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faCog'
import { faFilter } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faFilter'
import { faTimesCircle } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faTimesCircle'
import { faWindowClose } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faWindowClose'
import { faLayerPlus } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faLayerPlus'
import { faLayerMinus } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faLayerMinus'
import { faExclamation } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faExclamation'
import { faGavel } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faGavel'
import { faUsers } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faUsers'
import { faCircle } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faCircle'
import { faDotCircle } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faDotCircle'
import { faEye } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faEye'
import { faEyeSlash } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faEyeSlash'
import { faPencil } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faPencil'
import { faSearchPlus } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faSearchPlus'
import { faSearchMinus } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faSearchMinus'
import { faUser } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faUser'
import { faPlus } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faPlus'
import { faPlusCircle } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faPlusCircle'
import { faExpandWide } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faExpandWide'
import { faCloudDownload } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faCloudDownload'
import { faDownload } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faDownload'
import { faUpload } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faUpload'
import { faList } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faList'
import { faLockAlt } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faLockAlt'
import { faLockOpenAlt } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faLockOpenAlt'
import { faHandsUsd } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faHandsUsd'
import { faColumns } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faColumns'
import { faMinusCircle } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faMinusCircle'
import { faExclamationCircle } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faMinus } from '@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faMinus'
import { faCut } from "@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faCut"

import { faAsterisk as faAsteriskSolid } from "@/assets/fontawesome/@fortawesome/free-solid-svg-icons/faAsterisk";
import { faCheck as faCheckSolid } from "@/assets/fontawesome/@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes as faTimesSolid } from "@/assets/fontawesome/@fortawesome/free-solid-svg-icons/faTimes";
import { faSpinner as faSpinnerSolid } from "@/assets/fontawesome/@fortawesome/free-solid-svg-icons/faSpinner";
import { faBullhorn } from "@/assets/fontawesome/@fortawesome/pro-light-svg-icons/faBullhorn";
import { faArrowRight } from "@/assets/fontawesome/@fortawesome/free-solid-svg-icons/faArrowRight";

export default [
  faCut,
  faSpinner,
  faTimes,
  faAsterisk,
  faCheck,
  faUserCircle,
  faUser,
  faFolder,
  faFolders,
  faEnvelope,
  faAlignCenter,
  faBars,
  faUsersCog,
  faPaperPlane,
  faCloudUpload,
  faFileUpload,
  faFileAlt,
  faFileExclamation,
  faTrashAlt,
  faCog,
  faFilter,
  faTimesCircle,
  faBuilding,
  faChevronLeft,
  faHome,
  faWindowClose,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faLayerMinus,
  faLayerPlus,
  faExclamation,
  faGavel,
  faUsers,
  faCircle,
  faDotCircle,
  faEye,
  faEyeSlash,
  faFilePdf,
  faPencil,
  faSearchPlus,
  faSearchMinus,
  faPlusCircle,
  faPlus,
  faExpandWide,
  faCloudDownload,
  faLockAlt,
  faLockOpenAlt,
  faHandsUsd,
  faColumns,
  faMinusCircle,
  faExclamationCircle,
  faFileImage,
  faFileWord,
  faFileExcel,
  faFile,
  faDownload,
  faUpload,
  faList,
  faMinus,
  faAsteriskSolid,
  faCheckSolid,
  faTimesSolid,
  faSpinnerSolid,
  faBullhorn,
  faArrowRight
]

