import { DefineGetters } from "vuex-type-helper";
import { ActivityGetters, ActivityState } from "./types";

export const getters: DefineGetters<ActivityGetters, ActivityState> = {
  getActivitiesFromStore: (state: ActivityState) => state.activities,
  loadingGet: (state: ActivityState) => state.loadingGet,
  loadingCreate: (state: ActivityState) => state.loadingCreate,
  loadingUpdate: (state: ActivityState) => state.loadingUpdate,
  loadingDelete: (state: ActivityState) => state.loadingDelete,
  lastUpdate: (state: ActivityState) => state.lastUpdate,
  getActivityLinks:(state:ActivityState) => state.activityLinks,
  getActivityTotalRecords:({ activityLinks, activities }:ActivityState) => activityLinks && activityLinks.totalRecords ? activityLinks.totalRecords : activities.length
};
