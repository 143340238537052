import { AuthActions, AuthState, AuthMutations, AuthGetters } from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import { IAuthLoginResponse, IAuthCheckResponse, IUserAuthCheck } from "@/types";
import AuthService from "@/services/auth.service";
import AuthSsoService from "@/services/auth.sso.service";
export const actions: DefineActions<
  AuthActions,
  AuthState,
  AuthMutations,
  AuthGetters
> = {
  async authCheckGetUser({ commit }, token) {
    try {
      if(token){
        const data = await AuthSsoService.checkSSO(token);

        localStorage.setItem("access", token);
        commit(types.LOGIN, true);
        commit(types.SET_CURRENT_USER, data.data.data);
      }else{
        if(process.env.VUE_APP_MODE !== "native"){
          const checkAuth = await AuthService.check();
          const checkAuthData: IAuthCheckResponse = checkAuth.data;
          const authorized = checkAuthData.authorized;
          const user = checkAuthData.user;
          commit(types.LOGIN, true);
          commit(types.SET_CURRENT_USER, user);
          return { authorized };
        }

      }
      return;
      // const checkAuthData: IAuthCheckResponse = checkAuth.data;
      // const authorized = checkAuthData.authorized;
      // const user = checkAuthData.user;
      // commit(types.LOGIN, true);
      // commit(types.SET_CURRENT_USER, user);
      // return { authorized };
    } catch (e) {
      localStorage.removeItem("access");
      commit(types.LOGOUT, false);
      throw e;
    }
  },
  async authRefreshUserToken({ commit }) {
    const checkAuth = await AuthService.refreshToken();
    const checkAuthData: { token: string, user: IUserAuthCheck } = checkAuth.data;
    const token = checkAuthData.token;
    localStorage.setItem("access", token);
    const user = checkAuthData.user;
    commit(types.LOGIN, true);
    commit(types.SET_CURRENT_USER, user);
    return;
  },
  async login({ commit }, credentials) {
    const login = await AuthService.login(credentials);
    const loginData: any = login.data;
    const {token, tokenType} = loginData;
    if(tokenType === "application"){
      localStorage.setItem("access", token);
      commit(types.LOGIN, true);
    }else{
      localStorage.setItem("auth_mfa_request", token);
      commit(types.DO_MFA, true)
    }
  },
  async authGetAdminPreviewToken({  }) {
    const login = await AuthService.getAdminPreviewToken();
    const loginData: IAuthLoginResponse = login.data;
    const {token} = loginData;
    return token
  },
  async register({ }, userInfo) {
    const registrationResponse = await AuthService.register(userInfo);
    const registrationData = registrationResponse.data;
    return registrationData;
  },
  async checkEmailInUse({ }, email) {
    const registrationResponse = await AuthService.checkEmailInUse(email);
    const registrationData = registrationResponse.data;
    return registrationData;
  },

  async authCheckMfaRequest({}){
    const checkAuth = await AuthService.checkMfaRequest();
    const checkAuthData = checkAuth.data
    return checkAuthData
  },

  async authCheckMfaVerify({}){
    const checkAuth = await AuthService.checkMfaVerify();
    const checkAuthData = checkAuth.data
    return checkAuthData
  },

  async requestMfaMethod({}, mfaMethod){
    const checkAuth = await AuthService.requestMfaMethod(mfaMethod);
    const checkAuthData = checkAuth.data
    const {token} = checkAuthData
    localStorage.removeItem("auth_mfa_request");
    localStorage.setItem("auth_mfa_verify", token);
    return true
  },

  async verifyMfa({commit}, payload){
    const checkAuth = await AuthService.verifyMfaCode(payload);
    localStorage.removeItem("auth_mfa_verify");
    const checkAuthData = checkAuth.data
    const {token, tokenType} = checkAuthData;
    if(tokenType === "application"){
      localStorage.setItem("access", token);
      commit(types.LOGIN, true);
    }
    return checkAuthData
  },
  async passwordReset({}, credentials) {
    return await AuthService.passwordReset(credentials);
  },
  async updatePassword({}, credentials) {
    return await AuthService.updatePassword(credentials);
  },
  async canResetPassword({}) {
    return await AuthService.canResetPassword();
  },
  async canClaimAccount({}) {
    return await AuthService.claimAccount();
  },
  async requestClaimAccount({}, credentials) {
    const {data} = await AuthService.requestClaimAccount(credentials);
    return data
  },
  async confirmAccount({}) {
    return await AuthService.confirmAccount();
  },
  async requestConfirmAccount({ }, credentials) {
    const { data } = await AuthService.requestConfirmAccount(credentials);
    return data
  },
  async logout({ commit }) {
    try {
      try {
        // commit('activities/DROP_ACTIVITIES', {}, { root: true });
        // commit("user/CLEAR_USER_STORE", {}, { root: true });
      } catch (error) {
        /* Do Nothing */
      }
      try {
        // return ;
      } catch (error) {
        /* Do Nothing */
      }
    } catch (e) {
      /* Do Nothing */
    } finally {
      localStorage.removeItem("access");
      localStorage.removeItem("auth_mfa_request");
      localStorage.removeItem("auth_mfa_verify");
      commit(types.LOGOUT, false);
    }
  }
};
