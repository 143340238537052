import * as dateFns from "date-fns";

export const userBase = () => ({
  displayName: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phone: {
    primary: "",
    primaryVerified: {
      verified: "",
      verifiedOn: "",
      verifyCode: "",
      verifyInit: ""
    },
    secondary: "",
    secondaryVerified: {
      verified: "",
      verifiedOn: "",
      verifyCode: "",
      verifyInit: ""
    },
  },
  enableMfa: {
    email: false,
    sms: false,
    authenticator: false,
  },
  role: "",
  status: "",
  country: "",
  state: "",
  tags: [],
  settings: {
    mfaSettings:{
      smsPhoneNumber: ""
    },
    notificationSettings: {
      email: false,
      sms: false,
      push: false
    },
    preferDarkMode: false
  }
})


export const baseUser = () => ({
  firstName: "",
  lastName: "",
  displayName: "",
  email: "",
  role: "user",
  status: "",
  phone: {
    primary: "",
    secondary: ""
  },
  password: "",
  responsesToOnboardingQuestions: [],
  hasDoneOnboarding: false,
  country: "",
  state: "",
  tags: []
})

export const activityLoginBase = () => ({
  _id: "",
  name: "",
  userId: "",
  role: "",
  data: {}
})

export const activityLogoutBase = () => ({
  _id: "",
  name: "",
  userId: "",
  role: "",
  collectionType: "",
  documentId: "",
  data: [{}]
})

export const baseAppSettings = () => ({
    name: "",
    theme: {
      bodyBackgroundColor: "",
      logoUrl: "",
      logoIconUrl: "",
      primaryColor: "",
      secondaryColor: "",
      menuPosition: "",
      menuCollapse: "",
      views: {
        login: {
          backgroundColor: "",
          backgroundImage: ""
        },
        claimAccount: {
          backgroundColor: "",
          backgroundImage: ""
        },
        passwordReset: {
          backgroundColor: "",
          backgroundImage: ""
        },
        documents: {
          editMenuPosition: ""
        }
      }
    },
    settings: {
      views: {
        login: {
          signInText: "",
          buttonText: "",
          userNameLabel: ""
        },
        claimAccount: {
          claimAccountText: "",
          claimAccountLinkText: "",
          buttonText: ""
        },
        passwordReset: {
          passwordResetText: "",
          passwordTokenSentText: "",
          buttonText: ""
        },
        userDashboard: {
          message: ""
        },
        adminDashboard: {
          message: ""
        }
      }
    },
    deleted: false,
    active: false,
  });

  export const baseTenant = () => ({
    _id: "",
    name: "",
    maxAdmins: "",
    maxUsers: "",
    subdomain: "",
    sendingDomain: {
      primary: "",
      secondary: ""
    },
    defaultSendingAddress: {
      fromAddress: "",
      fromName: "",
      verified: false
    },
    sendingAddresses: [],
    status: ""
  })

  export const tenantAddressBase = () => ({
    fromAddress: "",
    fromName: "",
  });

  export const messageBase = () => ({
  });

  export function baseCommunication(): any {
    return Object.freeze({
      _id: "",
      title: "",
      deleted: false,
      userIds: [],
      message: "",
      deliveryDate: dateFns.addMinutes(new Date(), 25).toISOString(),
      status: "draft",
      attachments: [],
      expirationDate: dateFns.addHours(new Date(), 24).toISOString(),
      alternateDeliveryMethods: [],
      actions: [],
      priority: "high",
      sendImmediate: false,
      recipientType: "",
      tenantIds: []
    });
  }