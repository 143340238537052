import {
  ICommunication,
  CommunicationActions,
  CommunicationState,
  CommunicationMutations,
  CommunicationGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericMakeRequest
} from "@/store/helpers/actions";
import CommunicationService from "@/services/communications.service";
import { getCommunicationDefaults } from "@/helpers/communication";

import { format, addMinutes } from "date-fns";

export const actions: DefineActions<
  CommunicationActions,
  CommunicationState,
  CommunicationMutations,
  CommunicationGetters
> = {
  createCommunication: GenericCreateOne<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations,
    ICommunication
  >(CommunicationService, types.SET_ITEM as any),
  getCommunications: GenericGetMany<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations
  >(CommunicationService, "communications", types.SET_ITEM as any),
  getCommunication: GenericGetOne<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations
  >(CommunicationService, types.SET_ITEM as any, getCommunicationDefaults),
  deleteCommunication: GenericDeleteOne<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations
  >(CommunicationService, types.DELETE_ITEM as any),
  updateCommunication: GenericUpdateOne<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations,
    { id: string; update: Partial<ICommunication> }
  >(CommunicationService, types.SET_ITEM as any, getCommunicationDefaults, {
    before: (payload: Record<string, any>) => {
      if (payload.sendImmediate) {
        payload.deliveryDate = format(
          addMinutes(new Date(), 5),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ).toString();
      }
    }
  }),
  expireCommunication: GenericMakeRequest<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations
  >(
    CommunicationService.expireCommunication.bind(CommunicationService),
    types.SET_ITEM
  ),
  getCommunicationRecipients: GenericMakeRequest<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations
  >(CommunicationService.getCommunicationRecipients.bind(CommunicationService)),
  getCommunicationMetrics: GenericMakeRequest<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations
  >(CommunicationService.getCommunicationMetrics.bind(CommunicationService)),
  getCommunicationReaders: GenericMakeRequest<
    CommunicationState,
    CommunicationGetters,
    CommunicationActions,
    CommunicationMutations
  >(
    CommunicationService.getCommunicationReaders.bind(CommunicationService),
    null,
    {
      after(commit: Function, response: any) {
        commit(types.SET_STATES, {
          readersData: { readers: response.data, links: response.links }
        });
      }
    }
  )
};
