
import * as types from '@/store/mutation-types'
import { DefineMutations } from 'vuex-type-helper'
import { AuthState, AuthMutations } from "./types";
import { defaultState } from "./state"
import { IUserAuthCheck } from '@/types'
import has from "lodash/has";

export const mutations: DefineMutations<AuthMutations, AuthState> = {
  SET_STATE(state: AuthState, { key, value }): void {
    if (has(state, key)) {
      state[key] = value;
    }
  },
  [types.DO_MFA](state: AuthState) {
    state.isLoginMfa = true
  },
    [types.LOGIN](state: AuthState) {
        state.isLoggedIn = true
        state.isLoginMfa = false
    },
    [types.LOGOUT](state: AuthState) {
        state.isLoggedIn = false
        state.isLoginMfa = false
        state.loadedInitData = false
        state.user = null;
        const newState = Object.assign({}, state, defaultState())
        state = newState
    },
    [types.SET_CURRENT_USER](state: AuthState, user: IUserAuthCheck) {
        state.user = user
    }
}