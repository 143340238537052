import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import {
  IntegrationsState,
  IntegrationsMutations,
  IIntegrations,
  IntegrationFields
} from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericClearStore
} from "@/store/helpers";
import { defaultState } from "./state";
import { getMessageDefaults} from "@/helpers/message"

export const mutations: DefineMutations<IntegrationsMutations, IntegrationsState> = {
  [types.SET_STATE]: GenericSetState<IntegrationsState, keyof IntegrationsState>(),
  [types.SET_MESSAGE]: GenericSetItem<IntegrationsState, IIntegrations>(
    "integrations",
    getMessageDefaults
  ),
  [types.SET_MESSAGE_EDIT_FIELD]: GenericSetEditField<IntegrationsState, IntegrationFields>(),
  [types.SET_EDIT_MESSAGE]: GenericSetEdit<
    IntegrationsState,
    string | Partial<IIntegrations>
  >("integrations", getMessageDefaults),
  [types.DELETE_MESSAGE]: GenericDeleteItem<IntegrationsState>("integrations", "_id"),
  [types.CLEAR_MESSAGE_STORE]: GenericClearStore<IntegrationsState>(defaultState())
};
