import { Route } from "vue-router";
import store from "@/store";

const redirectToLogin = (next: any, from: string) => {
  if (from === "/") {
    return next({ path: "/login" });
  } else {
    return next({ path: "/login", query: { redirectFrom: from } });
  }

}

const isRouteAuthenticationRequired = (toRoute: Route) => ({
  required: toRoute.matched.some(route => route.meta.requireAuth),
  allowed: toRoute && toRoute.meta ? toRoute.meta.allowedRoles : ""
});

export const getCurrentUser = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
) => {
  try {

    // If the user has a token, validate it
    const hasToken = localStorage.getItem("access");
    if (!hasToken) {
      if (store.getters["auth/isLoggedIn"]) {
        return next('/logout');
      }
    } else {
      if (toRoute.path === fromRoute.path) {
        return next();
      }
      // Check to see if the user has a active token
      const authCheck = await store.dispatch("auth/authCheckGetUser");
      /* istanbul ignore next */
      if (authCheck && authCheck.authorized) {
        // if the user is authorized, then we have setup the current user
        // in the store. We can continue.
        const user = store.getters['auth/getCurrentUser']
        if(user.preferDarkMode) {
          store.commit('app/SET_DARK_MODE', true)
        }

        // if (!initUserDataSetup) {
        //   await store.dispatch("user/initUserData", _id);
        // }
        return next();
      }
    }

    throw new Error("Missing or invalid Token");
  } catch (e) {
    // If the user did not authenticate, check to see if the requested route
    // requires authorization. If not, let them continue.
    const { required } = isRouteAuthenticationRequired(toRoute);
    if (!required) {
      return next();
    }
    // The requested route requires authentication, and the
    // user is not authenticated. Send them to login page
    return redirectToLogin(next, toRoute.fullPath);
  }
};
