export default ({ }: any) => [
  {
    to: "/dashboard",
    text: "Dashboard",
    description: "manage users",
    icon: "users-cog",
  },
  {
    to: "/messages",
    text: 'Messages',
    description: "manage messages",
    icon: "envelope"
  }, 
]