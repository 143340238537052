import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  SocketIOState,
  SocketIOGetters,
  SocketIOMutations,
  SocketIOActions
} from "./types";

const namespaced = true;
export const namespace = "socketIO";

export const {
  mapState: socketIOMapState,
  mapGetters: socketIOMapGetters,
  mapMutations: socketIOMapMutations,
  mapActions: socketIOMapActions
} = createNamespacedHelpers<
  SocketIOState,
  SocketIOGetters,
  SocketIOMutations,
  SocketIOActions
>("socketIO");

export const socketIO: Module<SocketIOState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
