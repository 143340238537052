import { createNamespacedHelpers, Module } from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
  IntegrationsState,
  IntegrationsGetters,
  IntegrationsMutations,
  IntegrationsActions
} from "./types";

const namespaced = true;
export const namespace = "integrations";

export const {
  mapState: integrationsMapState,
  mapGetters: integrationsMapGetters,
  mapMutations: integrationsMapMutations,
  mapActions: integrationsMapActions
} = createNamespacedHelpers<
  IntegrationsState,
  IntegrationsGetters,
  IntegrationsMutations,
  IntegrationsActions
>(namespace);

export const integrations: Module<IntegrationsState, any> = {
  namespaced,
  state,
  getters,
  mutations,
  actions
};
