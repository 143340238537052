
import { DefineGetters } from 'vuex-type-helper'
import { AppGetters, AppState } from "./types";

export const getters: DefineGetters<AppGetters, AppState> = {
    getWindowSize: (state: AppState) => state.window,
    getShowSidebar: (state: AppState) => state.showSidebar,
    getScrollTop: (state: AppState) => state.scrollTop,
    getScreenSize: (state: AppState) => state.screenSize,
    getAppViewSettings: (state: AppState) => state.settings,
    getAppTheme: (state: AppState) => state.theme,
    getAppState: (state: AppState) => state.loadedInitData,
    isAppLaunched: (state: AppState) => state.app_launched,
    getUniversalLink: (state: AppState) => state.universalLink,
	getPushNotificationData: (state: AppState) => state.push_data,
	isDarkMode: (state: AppState) => state.isDarkMode,
	getCurrentScreen: (state: AppState) => state.currentScreen,
}