var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-full w-full flex justify-between items-start flex-col px-4"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "x-bg-red-400 w-full h-full flex items-center justify-center"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full px-4 x-bg-red-500 grid grid-flow-row-dense grid-cols-4"
            },
            [
              _vm.icon
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-span-1 h-24 mr-8 bg-red-600 flex items-center justify-center"
                    },
                    [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                  )
                : _vm._e(),
              _c("div", { class: _vm.icon ? "col-span-3" : "col-span-4" }, [
                _vm.title
                  ? _c("div", { staticClass: "text-2xl font-medium" }, [
                      _vm._v(_vm._s(_vm.title))
                    ])
                  : _vm._e(),
                _vm.description
                  ? _c("div", { staticClass: "mt-2" }, [
                      _vm._v(_vm._s(_vm.description))
                    ])
                  : _vm._e()
              ])
            ]
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "w-full h-24 flex flex-row-reverse justify-between items-center"
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", id: "login-btn" },
              nativeOn: {
                click: function($event) {
                  return _vm.onClickConfirm.apply(null, arguments)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.primaryButtonText) + " ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "mr-8",
              attrs: { id: "login-btn", type: "text" },
              nativeOn: {
                click: function($event) {
                  return _vm.onClickCancel.apply(null, arguments)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.secondaryButtonText) + " ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }