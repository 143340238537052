/**
 * A bunch of generic actions that map to specific
 * service methods on the BaseCollectionService class
 *
 * And may be more. So much more
 */

import BaseCollectionService from "@/services/base.collection.service";
import * as types from "@/store/mutation-types";
import { ActionContext, BasePayload } from "vuex-type-helper";
import { IActionQueryPayload } from "../types";
import { DefaultEditFunc } from "./mutations";
import { cloneDeep } from "lodash";

export function GenericCreateOne<
  State extends { editing: any },
  Getters,
  Actions,
  Mutations,
  P
>(apiService: BaseCollectionService, mutation: BasePayload): any {
  return async (
    { commit, state }: ActionContext<State, Getters, Actions, Mutations>,
    payload: P
  ): Promise<void> => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await apiService.createOne(payload);
      commit(mutation as any, response.data);

      if (state.editing && state.editing._id === response.data._id) {
        commit(
          types.SET_STATE as any,
          { key: "editing", value: response.data } as any
        );
      }
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  };
}

/**
 * Action to query for some collection and dump results and links in relevant
 * places within store
 *
 * the default behavior is to replace all that was there
 * even though we could
 */

export function GenericGetMany<State, Getters, Actions, Mutations>(
  apiService: BaseCollectionService,
  collectionKey: BasePayload | string,
  setItemMutation: BasePayload | string
): any {
  return async (
    { commit }: ActionContext<State, Getters, Actions, Mutations>,
    { query, merge = false }: IActionQueryPayload
  ): Promise<void> => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await apiService.getMany(query);
      const responseData = response.data;

      if (!merge) {
        commit(
          types.SET_STATE as any,
          { key: collectionKey, value: responseData } as any
        );
      } else {
        commit(setItemMutation as any, responseData as any);
      }

      commit(
        types.SET_STATE as any,
        { key: "links", value: response.links } as any
      );
      commit(
        types.SET_STATE as any,
        { key: "lastUpdate", value: new Date() } as any
      );
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  };
}

/**
 * The generic method to kill all generic methods
 * We want a generic method that we can tell a service to run
 * on some payload and optionally what mutation to run on the response
 */
export function GenericMakeRequest<State, Getters, Actions, Mutations>(
  serviceMethod: Function,
  mutation?: any,
  options?: { before?: Function; after?: Function }
): any {
  return async (
    { commit }: ActionContext<State, Getters, Actions, Mutations>,
    payload: any[] = []
  ): Promise<void> => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );

      if (options && options.before) {
        options.before(payload);
      }

      const response = await serviceMethod(payload);
      if (mutation) commit(mutation, response.data);

      if (options && options.after) {
        options.after(commit, response);
      }
      return response;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  };
}

export function GenericGetOne<State, Getters, Actions, Mutations>(
  apiService: BaseCollectionService,
  mutation: any,
  defaultEditGenerator?: DefaultEditFunc,
  options?: { before?: Function; after?: Function }
): any {
  return async (
    { commit }: ActionContext<State, Getters, Actions, Mutations>,
    payload: any
  ): Promise<void> => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      if (options && options.before) {
        options.before(payload);
      }
      const response = await apiService.getOne(payload);
      const responseData = response.data;
      const editData = defaultEditGenerator
        ? defaultEditGenerator(response.data)
        : response.data;
      commit(mutation, cloneDeep(editData));
      commit(
        types.SET_STATE as any,
        { key: "editing", value: cloneDeep(editData) } as any
      );
      commit(
        types.SET_STATE as any,
        { key: "lastUpdate", value: new Date() } as any
      );

      if (options && options.after) {
        options.after(response.data);
      }
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  };
}

export function GenericDeleteOne<State, Getters, Actions, Mutations>(
  apiService: BaseCollectionService,
  mutation: any
): any {
  return async (
    { commit }: ActionContext<State, Getters, Actions, Mutations>,
    payload: string
  ): Promise<void> => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await apiService.deleteOne(payload);
      const responseData = response.data;
      commit(mutation, payload as any);
      commit(
        types.SET_STATE as any,
        { key: "lastUpdate", value: new Date() } as any
      );
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  };
}

export function GenericUpdateOne<
  State extends { editing: any },
  Getters,
  Actions,
  Mutations,
  P extends { id: string; update: any }
>(
  apiService: BaseCollectionService,
  mutation: any,
  defaultEditGenerator?: Function,
  options?: { before?: Function; after?: Function }
): any {
  return async (
    { commit, state }: ActionContext<State, Getters, Actions, Mutations>,
    payload: P
  ): Promise<void> => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      if (options?.before) {
        options.before(payload.update);
      }
      const response = await apiService.updateOne(payload.id, payload.update);
      if (options?.after) {
        options.after(response.data);
      }
      const editData = defaultEditGenerator
        ? defaultEditGenerator(response.data)
        : response.data;
      if (state.editing && state.editing._id === payload.id) {
        commit(
          types.SET_STATE as any,
          { key: "editing", value: cloneDeep(editData) } as any
        );
      }

      commit(mutation, cloneDeep(editData));
      commit(
        types.SET_STATE as any,
        { key: "lastUpdate", value: new Date() } as any
      );
      return response.data;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  };
}
