// import { mapGetters } from "vuex"
// import { isEmpty, get } from "lodash";

// import {  Device } from "@nativescript/core"
// import { messaging } from "@nativescript/firebase/messaging";
import store from '@/store'


// // const { VUE_APP_ENV } = process.env
// import { Frame } from "@nativescript/core";
// import { appMapGetters, appMapMutations } from "@/store/modules/app";
// import { authMapGetters } from "@/store/modules/auth";
import Vue from "vue";

export const globalCSSMixin = Vue.extend({
    computed: {
        $_css_PrimaryBackgroundColor(): string {
            const isDarkMode = store.getters['app/isDarkMode'];
            return isDarkMode ? "bg-gray-800" : "bg-gray-100";
        },
        $_css_PrimaryBackgroundColorLight(): string {
            const isDarkMode = store.getters['app/isDarkMode'];
            return isDarkMode ? "bg-gray-800" : "bg-white";
        },
        $_css_SecondaryBackgroundColor(): string {
            const isDarkMode = store.getters['app/isDarkMode'];
            return isDarkMode ? "bg-gray-800" : "bg-gray-100";
        },
        $_css_PrimaryTextColor(): string {
            const isDarkMode = store.getters['app/isDarkMode'];
            return isDarkMode ? "text-gray-200" : "text-gray-800";
        },
        $_css_SecondaryTextColor(): string {
            const isDarkMode = store.getters['app/isDarkMode'];
            return isDarkMode ? "text-gray-300" : "text-gray-700";
        },
        $_css_PlainTextLink(): string {
            const isDarkMode = store.getters['app/isDarkMode'];
            const baseClasses = "font-semibold cursor-pointer";
            return `${baseClasses} ${isDarkMode ? "text-red-200" : "text-red-900"}`;
        },
        $_css_UIViewBlock(): string {
            const isDarkMode = store.getters['app/isDarkMode'];
            const baseClasses = "pt-16 pb-12 px-4 sm:px-10 relative";
            return `${baseClasses} ${isDarkMode ? "bg-gray-900" : "bg-white shadow-sm"}`;
        },
    }
})