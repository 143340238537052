import has from "lodash/has";
import set from "lodash/set";
import { PropertyPath } from "lodash";

export type GenericSetStateType<T, K> = (
  state: T,
  payload: { key: K; value: any }
) => void;

export type InterfaceMap<T> = {
  [k in keyof T]: T[k];
};

export type GenericSetStatesType<T, K> = (
  state: T,
  payload: Partial<K>
) => void;

export function GenericSetState<
  T extends Object,
  K extends PropertyPath
>(): GenericSetStateType<T, K> {
  return (state: T, { key, value }: { key: K; value: any }): void => {
    if (has(state, key)) {
      set(state, key, value);
    }
  };
}

export function GenericSetStates<T extends Object>(): GenericSetStatesType<
  T,
  InterfaceMap<T>
> {
  return (state: T, payload: Partial<InterfaceMap<T>>): any => {
    for (const key of Object.keys(payload)) {
      if (has(state, key)) {
        set(state, key, (payload as any)[key]);
      }
    }
  };
}

//We want a way to specify additional keys for the auth state - for nested property TODO: do that @disco-dingo

export function GenericSetEditField<
  T extends { editing: any },
  K extends PropertyPath
>(): GenericSetStateType<T, K> {
  return (state: T, { key, value }: { key: K; value: any }): void => {
    if (state.editing && has(state.editing, key)) {
      set(state.editing, key, value);
    }
  };
}

export function GenericDeleteItem<T>(collectionKey: keyof T, field: any): any {
  return (state: T, value: string): void => {
    const collection = state[collectionKey];
    if (Array.isArray(collection)) {
      const indexOfItem = collection.findIndex(
        (item: T[typeof collectionKey]) => (item as any)[field] === value
      ); //indexOfItemWithProp(state[collectionKey] as any, field, value);
      if (indexOfItem !== -1) {
        (state[collectionKey] as any).splice(indexOfItem, 1);
      }
    }
  };
}

export type DefaultEditFunc = (
  overrides: Record<string, any>
) => Record<string, any>;
export type GenericSetEditFunc<T, P> = (state: T, payload: P) => void;

export function GenericSetEdit<T extends { editing: any }, P>(
  collectionKey: keyof T,
  defaultEditGenerator: DefaultEditFunc
): GenericSetEditFunc<T, P> {
  return (state: T, initData: P): void => {
    const collection = state[collectionKey];
    if (Array.isArray(collection)) {
      if (typeof initData === "string") {
        const itemToEdit =
          collection.find((item: any) => item._id === initData) || {}; //indexOfItemWithProp(state[collectionKey] as any, field, value);
        state.editing = defaultEditGenerator(itemToEdit);
      } else {
        state.editing = defaultEditGenerator(initData);
      }
    }
  };
}

export type GenericSetItemFunc<T, P> = (state: T, payload: P | P[]) => void;

export function GenericSetItem<T, P extends { _id: string }>(
  collectionKey: keyof T,
  defaultEditGenerator: DefaultEditFunc
): GenericSetItemFunc<T, P> {
  return (state: T, payload: P | P[]): void => {
    const add = (toAdd: P): void => {
      const collection = state[collectionKey];
      if (Array.isArray(collection)) {
        const itemIndex = collection.findIndex(
          (item: any) => item._id === toAdd._id
        );
        if (itemIndex !== -1) {
          collection.splice(itemIndex, 1, defaultEditGenerator(toAdd));
        } else {
          collection.push(defaultEditGenerator(toAdd));
        }
      }
    };
    if (Array.isArray(payload)) {
      payload.forEach((item: any) => add(item));
    } else {
      add(payload);
    }
  };
}

export function GenericClearStore<T>(defaultState: T): any {
  return (state: T): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state = Object.assign(state, defaultState);
  };
}