import {
  IntegrationsActions,
  IntegrationsState,
  IntegrationsMutations,
  IntegrationsGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericGetMany
} from "@/store/helpers/actions";
import IntegrationsService from "@/services/integrations.service";

export const actions: DefineActions<
  IntegrationsActions,
  IntegrationsState,
  IntegrationsMutations,
  IntegrationsGetters
> = {
  getIntegrations: GenericGetMany<
    IntegrationsState,
    IntegrationsGetters,
    IntegrationsActions,
    IntegrationsMutations
  >(IntegrationsService, "integrations", types.SET_MESSAGE as any)
};
