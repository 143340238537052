import { DefineMutations } from "vuex-type-helper";
import { SocketIOState, SocketIOMutations } from "./types";

export const mutations: DefineMutations<SocketIOMutations, SocketIOState> = {
  SOCKET_CONNECT(): void {
    console.log("mutation SOCKET_CONNECT");
  },
  SOCKET_DISCONNECT(state: SocketIOState): void {
    console.log("mutation SOCKET_DISCONNECT");
    state.authenticated = false;
  },
  SOCKET_AUTHENTICATED(state: SocketIOState): void {
    console.log("mutation SOCKET_AUTHENTICATED");
    state.authenticated = true;
  }
};
