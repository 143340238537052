import { authMapGetters } from "@/store/modules/auth";
import { appMapGetters } from "@/store/modules/app";
import Vue from "vue";
import { format } from "date-fns";

import { IAppNavigateOptions, IAppNavigateRoute } from "@/types";

export const globalGeneralMixin = Vue.extend({
  methods: {
    $appNavigate (path:string, options: IAppNavigateOptions = {}) {
      if(this.$isWeb){
        if(options.clearHistory === true) {
          this.$router.replace(path).catch(() => {});
        }else{
          this.$router.push(path).catch(() => {});
        }
      }else{
          this.$navigator.navigate(path, options)
      }
    },
    $_formatDateSlashes(date: string | Date) {
      if (date instanceof Date) {
        return format(date, "M/d/yyyy");
      }
      return format(new Date(date), "M/d/yyyy");
    },
  },
  computed: {
    ...authMapGetters({
      $_isLoggedIn: "isLoggedIn",
      $_getCurrentUser: "getCurrentUser",
      $_getCurrentUserId: "getCurrentUserId",
      $_getCurrentUserRole: "getCurrentUserRole",
    }),
    ...appMapGetters({
      $_isDarkMode: "isDarkMode",
      $_currentScreen: "getCurrentScreen",
    }),
    $appCurrentRoute(): IAppNavigateRoute {
      if (this.$isWeb) {
        return {
          path: this.$route.path,
          name: this.$route.name,
          meta: this.$route.meta,
          query: this.$route.query
        };
      } else {
        const { route = {} } = this.$navigator;
        const { name = "", path = "", meta = {}, query = {} } = route;
        return {
          path,
          name,
          meta,
          query
        };
      }
    },
  },
});
