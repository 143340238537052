var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.getWebDropdownComponent, {
    tag: "component",
    attrs: {
      selectedValue: _vm.selectedValue,
      dropDownItems: _vm.dropDownItems,
      disabled: _vm.disabled,
      preventPopover: _vm.preventPopover
    },
    on: {
      onClickPlaceholder: function($event) {
        return _vm.$emit("onClickPlaceholder", $event)
      },
      input: function($event) {
        return _vm.$emit("input", $event)
      },
      change: function($event) {
        return _vm.$emit("change", $event)
      },
      customAction: _vm.onCustomAction
    },
    scopedSlots: _vm._u([
      {
        key: "labelHasValue",
        fn: function({ selected }) {
          return [
            _c("span", { staticClass: "text-gray-900 text-lg" }, [
              _vm._v(_vm._s(selected.label))
            ])
          ]
        }
      },
      {
        key: "labelNoValue",
        fn: function() {
          return [_vm._v(_vm._s(_vm.labelNoValue))]
        },
        proxy: true
      },
      {
        key: "innerDropdownItem",
        fn: function({ item }) {
          return [
            _c("div", { staticClass: "flex justify-between items-center" }, [
              _c("span", { staticClass: "text-lg" }, [
                _vm._v(_vm._s(item.label))
              ]),
              _c("span", { staticClass: "text-xs" }, [_vm._v(_vm._s(item.sub))])
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }