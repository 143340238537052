import {
  IDocuments,
  DocumentsActions,
  DocumentsState,
  DocumentsMutations,
  DocumentsGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetMany,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne
} from "@/store/helpers/actions";
import DocumentsService from "@/services/documents.service";

export const actions: DefineActions<
  DocumentsActions,
  DocumentsState,
  DocumentsMutations,
  DocumentsGetters
> = {
  createDocuments: GenericCreateOne<
    DocumentsState,
    DocumentsGetters,
    DocumentsActions,
    DocumentsMutations,
    IDocuments
  >(DocumentsService, types.SET_ITEM as any),
  getDocuments: GenericGetMany<
    DocumentsState,
    DocumentsGetters,
    DocumentsActions,
    DocumentsMutations
  >(DocumentsService, "documents", types.SET_ITEM as any),
  getDocument: GenericGetOne<
    DocumentsState,
    DocumentsGetters,
    DocumentsActions,
    DocumentsMutations
  >(DocumentsService, types.SET_ITEM as any),
  deleteDocuments: GenericDeleteOne<
    DocumentsState,
    DocumentsGetters,
    DocumentsActions,
    DocumentsMutations
  >(DocumentsService, types.DELETE_ITEM as any),
  updateDocuments: GenericUpdateOne<
    DocumentsState,
    DocumentsGetters,
    DocumentsActions,
    DocumentsMutations,
    { id: string; update: Partial<IDocuments> }
  >(DocumentsService, types.SET_ITEM as any)
};
