<template web>
  <component
    :is="getWebDropdownComponent"
    :selectedValue="selectedValue"
    :dropDownItems="dropDownItems"
    :disabled="disabled"
    :preventPopover="preventPopover"
    @onClickPlaceholder="$emit('onClickPlaceholder', $event)"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    @customAction="onCustomAction"
  >
    <template v-slot:labelHasValue="{ selected }">
             <!-- {{selected}} -->
      <span class="text-gray-900 text-lg"
        >{{ selected.label }}</span
      >
    </template>
    <template v-slot:labelNoValue>{{ labelNoValue }}</template>
    <template v-slot:innerDropdownItem="{ item }">
      <div class="flex justify-between items-center">
        <span class="text-lg">{{ item.label }}</span>
        <span class="text-xs">{{ item.sub }}</span>
      </div>
    </template>

  </component>
</template>

<script>  
import get from "lodash/get"
import CustomDropdownRoot from "./CustomDropdownTemplates/CustomDropdownRoot.vue";

export default {
    props: {
    selectedValue: {
      type: [Array, Object, String, Number, Date],
      default() {
        return null;
      },
    },
    dropDownItems: {
      type: [Array],
      default() {
        return null;
      },
    },
    labelNoValue: {
      type: String,
      default: "Select"
    },
    preventPopover: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    },
    data(){
        return {
            selected: false,
        }
    },
methods: {
    async getOptions() {
    this.selected = true;
      this.$emit('onClickPlaceholder', true)
      const { dropDownItems, labelNoValue } = this;
      if(dropDownItems.length && !this.$isWeb){
        try {
          this.$doTapticSelection();

          const options = dropDownItems.map(a => a.label)

           // eslint-disable-next-line no-undef
          const result = await action(labelNoValue,"Close", options )

          if(result === "Add New Payment Method"){
            this.$emit('customAction', { key: 'addNewCard' })
            return;
          }

          const selected = dropDownItems.find(a => a.label === result)

          if(selected.value === "emitCustomAction"){
            this.$emit('customAction', selected.customAction)
          }else{
            this.$emit('change', selected.value)
            this.$emit('input', selected.value)
          }

        } catch (error) {
          // TODO: Handle error better?
        } finally {
        this.selected = false;

        }
      }

    },
    onCustomAction() {}
},
computed: {
    getWebDropdownComponent() {
      const componentMap = {
        none: CustomDropdownRoot,
      }
      return get(componentMap, this.template, componentMap['none'])
    },
  displaySelectedValue(){
    const {dropDownItems, selectedValue} = this;
    const selected = dropDownItems.find(i => i.value === selectedValue)
    return selected ? selected.label : selectedValue
  },
  selectClassList(){
    const { $_isDarkMode, selected } = this;
    const classes = ["border", "px-3", "transition-opacity"];
    
    if(selected){
      classes.push("opacity-50")
    }else{
      classes.push("opacity-100")
    }

    if($_isDarkMode){
      classes.push("bg-gray-700")
      classes.push("border-gray-900")
      classes.push("text-gray-200")
    }else{
      classes.push("border-gray-300")
      classes.push("bg-gray-200")
      classes.push("text-gray-800")
    }

    return classes;
  }
}
}
</script>