import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { AppSettingsState, AppSettingsMutations, ISetAppSettingsEditField } from "./types";
import { IAppSettings } from "@/types/interfaces/appSettings";
import has from "lodash/has";
import set from "lodash/set";
import { getAppSettingsDefaults } from "@/helpers/appSettings";

export const mutations: DefineMutations<AppSettingsMutations, AppSettingsState> = {
  [types.DROP_APP_SETTINGS](state: AppSettingsState): void {
    state.appSettings = [];
  },

  [types.SET_APP_SETTINGS](state: AppSettingsState, payload: IAppSettings | IAppSettings[]) {
    const add = (appsetting: IAppSettings) => {
      const appSettingIndex = state.appSettings.findIndex(u => u._id === appsetting._id);
      if (appSettingIndex > -1) {
        state.appSettings.splice(appSettingIndex, 1, getAppSettingsDefaults(appsetting));
      } else {
        state.appSettings.push(getAppSettingsDefaults(appsetting));
      }
    };
    if (Array.isArray(payload)) {
      payload.forEach(appsetting => add(appsetting));
    } else {
      add(payload);
    }
  },
  [types.SET_APP_SETTINGS_EDIT_FIELD](
    state: AppSettingsState,
    { key, value }: ISetAppSettingsEditField
  ): void {
    if (has(state.editing, key)) {
      set(state.editing, key, value)
    }
  },
  [types.REMOVE_APP_SETTINGS](state: AppSettingsState, appSettingsID: string): void {
    state.appSettings = state.appSettings.filter(p => p._id !== appSettingsID);
  },

  [types.SET_APP_SETTINGS_STATE](state: AppSettingsState, { key, value }): void {
    if (has(state, key)) {
      state[key] = value;
    }
  },

 [types.SET_APP_SETTINGS_LINKS](state:AppSettingsState,links): void {
    state.appSettingsLinks = links;
  },

  [types.SET_EDIT_APP_SETTINGS](state:AppSettingsState, appSetting: string | Partial<IAppSettings>): void {
    if(typeof appSetting === "string"){
      const findToEdit = state.appSettings.find((f: IAppSettings) => f._id === appSetting) || {}
      const edit = getAppSettingsDefaults(findToEdit)
      state.editing = edit;
    }else{
      const edit = getAppSettingsDefaults(appSetting);
      state.editing = edit;
    }
  },

};