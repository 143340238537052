import { cloneDeep, has, get } from "lodash";

const { VUE_APP_API_HTTP_SERVER = "" } = process.env;

export function getPathAPI(): string {
  return VUE_APP_API_HTTP_SERVER;
}

export function getFilesPathAPI(): string {
  return VUE_APP_API_HTTP_SERVER;
}

export const loadingTimeout = (time: number): Promise<void> => {
  const timeout = time || 540;
  return new Promise((resolve: any) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

export function hasRequiredFields(
  object: any,
  requiredFields: string[]
): boolean {
  let hasFields = true;
  const actualFields = cloneDeep(object);
  for (const field of requiredFields) {
    if (!has(actualFields, field) || !get(actualFields, field)) {
      hasFields = false;
      break;
    }
    if (
      Array.isArray(get(actualFields, field)) &&
      get(actualFields, field).length <= 0
    ) {
      hasFields = false;
      break;
    }
  }
  return hasFields;
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "…";
}

export function generatePassword() {
  const length = Math.floor(Math.random() * (36 - 24 + 1) + 24);
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let pw = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    pw += charset.charAt(Math.floor(Math.random() * n));
  }
  return pw;
}
