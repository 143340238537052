
import Vue from "vue";

interface IPageMessage {
  removeTimer: any;
}

export default Vue.extend({
  name: "PageMessage",
  props: {
    type: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    closable: {
      type: Boolean,
      default: true
    },
    effect: {
      type: String,
      required: false,
      default: "light"
    }
  },
  data(): IPageMessage {
    return {
      removeTimer: null
    };
  },
  watch: {
    message: function() {
      clearTimeout(this.removeTimer);
      this.removeTimer = setTimeout(() => this.close(), 3200);
    }
  },
  mounted() {},
  destroyed() {},
  methods: {
    close() {
      this.$emit("close");
      clearTimeout(this.removeTimer);
    }
  },
  computed: {}
});
