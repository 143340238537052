import { createNamespacedHelpers, Module } from 'vuex'
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";
import {
    AuthState,
    AuthGetters,
    AuthMutations,
    AuthActions
} from "./types"


const namespaced = true
export const namespace = "auth"

export const {
    mapState: authMapState,
    mapGetters: authMapGetters,
    mapMutations: authMapMutations,
    mapActions: authMapActions
} = createNamespacedHelpers<AuthState, AuthGetters, AuthMutations, AuthActions>(
    'auth'
)

export const auth: Module<AuthState, any> = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}