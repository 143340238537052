
import Vue from "vue";
// import { throttle } from "lodash";
import { appMapGetters } from "@/store/modules/app";

export default Vue.extend({
  name: "WebBasePageHeader",
  data() {
    return {};
  },
  methods: {

  },
  computed: {
    ...appMapGetters(["getScrollTop"])
  }
});
