
import Vue from "vue";

export default Vue.extend({
  props: {
    isOperations: {
      type: Boolean,
      default: false
    },
    columnKey: {
      type: String,
      required: true
    },
    column: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data(){
    return {
      sortOrder: ""
    }
  },
  methods: {
    doSort(){
      if(this.columnData.allowSort){
        const order = this.sortOrder === "descending" ? "ascending" : "descending";
        const prop = this.columnKey;
        this.$emit('sortChange', { order, prop })
        this.sortOrder = order
      }
    }
  },
  computed: {
    isSortedBy(): any{
      const { columnKey } = this;
      const { query = {} } = this.$route
      const { __sort = "" } = query
      if(!columnKey || !__sort) return false
      return __sort === columnKey || __sort === `-${columnKey}`
    },  
    columnData(): any {
      const {
        colClassName: className = "",
        minWidth = "120",
        colWidth: width = "",
        allowSort = false,
        formatDataKey,
        hideOnMobile = false,
      } = this.column;
      const label =
        typeof formatDataKey === "function" ? formatDataKey() : formatDataKey;
      return {
        className,
        minWidth,
        width,
        allowSort,
        label,
        hideOnMobile,
      };
    },
    columnClassList(): string[] {
      const { $_css_SecondaryTextColor } = this;
      const baseClasses = [
        "px-3",
        "py-3.5",
        "text-left",
        "text-sm",
        "font-semibold",
        "select-none",
        $_css_SecondaryTextColor,
      ];
      const { columnData } = this;
      if (columnData.hideOnMobile) {
        baseClasses.push("lg:table-cell");
        baseClasses.push("hidden");
      }
      return baseClasses;
    },
  },
});
