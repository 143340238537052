var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "main-view" }, [
    _c(
      "div",
      { staticClass: "main-icon" },
      [
        _c("font-awesome-icon", {
          attrs: { icon: ["fal", "exclamation-circle"] }
        })
      ],
      1
    ),
    _c("h1", { staticClass: "main-header" }, [_vm._v(_vm._s(_vm.title))]),
    _c("h2", { staticClass: "text-center" }, [_vm._v(_vm._s(_vm.subtitle))]),
    _c(
      "div",
      { staticClass: "text-lg" },
      [
        _c("router-link", { attrs: { to: _vm.actionRedirectPath } }, [
          _c("i", { staticClass: "el-icon-back mr-2" }),
          _vm._v(_vm._s(_vm.actionButtonTitle))
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }