import { DefineGetters } from 'vuex-type-helper'
import { UserGetters, UserState } from "./types";

export const getters: DefineGetters<UserGetters, UserState> = {
    getEditingUser: (state: UserState) => state.editing,
    getUsersFromStore: (state: UserState) => state.users,
    getUserLinks: (state: UserState) => state.links,
    getTotalRecords: ({ links, users }: UserState) =>
      links && links.totalRecords ? links.totalRecords : users.length,
    getUserById: (state: UserState) => (userId: string) =>
      state.users.find((user: any) => user._id === userId),
}